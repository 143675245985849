import React, {useState} from "react";
import Sidebar from "./Sidebar"
import Navbar from "./Navbar";
import Footer from "./Footer";
import AddListModal from "./AddListModal";
import Login from "./Login";
import Register from "./Register";
import ResendConfirmation from "./ResendConfirmation";
import Confirmation from "./Confirmation";
import APIService from "../../services/api.service";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ResendUnlock from "./ResendUnlock";
import Unlock from "./Unlock";
import Account from "./Account";
import CategoriesGrid from "./CategoriesGrid";
import List from "./List";
import Reminders from "./Reminders";
import {Helmet} from "react-helmet";
import AddItemModal from "./AddItemModal";
import ActivityInvitation from "./ActivityInvitation";
import Assignees from "./Assignees";
import AddAssigneeModal from "./AddAssigneeModal";
import Activity from "./Activity";
import ActivityReminder from "./ActivityReminder";
import Pricing from "./Pricing";
import PaymentModal from "./PaymentModal";
import ActivitiesList from "./ActivitiesList";
import DashboardStats from "./DashboardStats";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Awards from "./Awards";
import AddNudgeModal from "./AddNudgeModal";

export default function Dashboard() {

    window.addEventListener('load', function load() {
        document.body.classList.remove('hidden');
        const loader = document.getElementById('loader');
        setTimeout(function () {
            loader.classList.add('fadeOut');
        }, 600);

    });

    const [authenticatedUser, setAuthenticatedUser] = useState(APIService.getHeaders());

    // Handle the routing withing the dashboard
    var url = new URL(window.location);
    let activeComponent;
    var activeMenu = "";
    let body = document.getElementsByTagName('body')[0];
    var showSidebar = false;
    var showNavbar = false;
    var pageTitle = 'Reminded | Dashboard'
    var showTourLink = false;

    if (window.location.pathname === '/confirmation') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Email confirmation";

        var confirmation_token = url.searchParams.get("confirmation_token");
        if (confirmation_token) {
            window.history.replaceState(null, "Email confirmation", "/confirmation?confirmation_token=" + confirmation_token);
            activeComponent = <Confirmation/>;
        } else {
            window.location.assign("/resend-confirmation");
        }
    }

    if (window.location.pathname === '/unlock') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Unlock account";

        var unlock_token = url.searchParams.get("unlock_token");
        if (unlock_token) {
            window.history.replaceState(null, "Unlock account", "/unlock?unlock_token=" + unlock_token);
            activeComponent = <Unlock/>;
        } else {
            window.location.assign("/resend-unlock");
        }
    }

    if (window.location.pathname === '/activity_invitation') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Activity invitation";

        var token = url.searchParams.get("token");
        var id = url.searchParams.get("id");
        var response = url.searchParams.get("response");
        if (token && id && response) {
            window.history.replaceState(null, "Activity invitation", "/activity_invitation?token=" + token + "&id=" + id + "&response=" + response);
            activeComponent = <ActivityInvitation/>;
        } else {
            window.location.assign("/");
        }
    }

    if (window.location.pathname === '/activity_reminder') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Activity reminder";

        var token = url.searchParams.get("token");
        var id = url.searchParams.get("id");
        var response = url.searchParams.get("response");
        if (token && id && response) {
            window.history.replaceState(null, "Activity reminder", "/activity_reminder?token=" + token + "&id=" + id + "&response=" + response);
            activeComponent = <ActivityReminder/>;
        } else {
            window.location.assign("/");
        }
    }

    if (window.location.pathname === '/privacy') {
        body.classList.add('bg-gray-200');
        pageTitle = pageTitle + " - Privacy Policy";

        window.history.replaceState(null, "Privacy Policy", "/privacy");
        activeComponent = <PrivacyPolicy/>;

    }

    if (window.location.pathname === '/terms') {
        body.classList.add('bg-gray-200');
        pageTitle = pageTitle + " - Terms & Conditions";

        window.history.replaceState(null, "Terms & Conditions", "/terms");
        activeComponent = <TermsAndConditions/>;

    }

    if (window.location.pathname === '/forgot-password') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Send password reset link";

        window.history.replaceState(null, "Send password reset link", "/forgot-password");
        activeComponent = <ForgotPassword/>;
    }

    if (window.location.pathname === '/password/edit') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " - Reset password";

        var reset_password_token = url.searchParams.get("reset_password_token");
        if (reset_password_token) {
            window.history.replaceState(null, "Reset password", "/password/edit?reset_password_token=" + reset_password_token);
            activeComponent = <ResetPassword/>;
        } else {
            window.location.assign("/forgot-password");
        }
    }


    if (!authenticatedUser) {
        body.classList.remove('bg-gray-200');
        showSidebar = false;
        showNavbar = false;

        if (window.location.pathname === '/register') {
            pageTitle = pageTitle + " - Sign up";
            window.history.replaceState(null, "Sign up", "/register");
            activeComponent = <Register/>;
        } else if (window.location.pathname === '/resend-unlock') {
            pageTitle = pageTitle + " - Resend unlock link";
            window.history.replaceState(null, "Resend unlock link", "/resend-unlock");
            activeComponent = <ResendUnlock/>;
        } else if (window.location.pathname === '/resend-confirmation') {
            pageTitle = pageTitle + " - Resend email confirmation";
            window.history.replaceState(null, "Resend email confirmation", "/resend-confirmation");
            activeComponent = <ResendConfirmation/>;
        } else if (window.location.pathname === '/forgot-password' ||
            window.location.pathname === '/password/edit' ||
            window.location.pathname === '/confirmation' ||
            window.location.pathname === '/unlock' ||
            window.location.pathname === '/activity_invitation' ||
            window.location.pathname === '/activity_reminder' ||
            window.location.pathname === '/privacy' ||
            window.location.pathname === '/terms') {
            // do nothing - these URLs are already handled above
        } else {
            pageTitle = pageTitle + " - Sign in";
            window.history.replaceState(null, "Sign in", "/login");
            activeComponent = <Login/>;
        }
    }

    function reset_user_session() {
        if (!APIService.getAssignees()) {
            APIService.resetAssignees();
        }

        if (!APIService.getTasklists()) {
            APIService.resetTasklists();
        }

        if (!APIService.getCurrentUser()) {
            APIService.resetCurrentUser();
        }
    }

    if (authenticatedUser) {
        body.classList.add('g-sidenav-show');
        body.classList.add('bg-gray-200');

        showSidebar = true;
        showNavbar = true;

        if (window.location.pathname === '/logout') {
            APIService.logout();
            window.location.assign("/");
        } else if (window.location.pathname === '/account') {
            reset_user_session();
            pageTitle = pageTitle + " - Account";
            window.history.replaceState(null, "Account", "/account");
            activeComponent = <Account/>;
        } else if (window.location.pathname === '/forgot-password' ||
            window.location.pathname === '/password/edit' ||
            window.location.pathname === '/confirmation' ||
            window.location.pathname === '/unlock' ||
            window.location.pathname === '/activity_invitation' ||
            window.location.pathname === '/activity_reminder') {
            body.classList.remove('bg-gray-200');
            showSidebar = false;
            showNavbar = false;
        } else if (window.location.pathname === '/privacy' ||
            window.location.pathname === '/terms') {
            showSidebar = false;
            showNavbar = false;
        } else if (window.location.pathname === "/lists") {
            reset_user_session();
            pageTitle = pageTitle + " - My lists";
            window.history.replaceState(null, "My lists", window.location.pathname);
            activeComponent = <CategoriesGrid/>;
            activeMenu = 'lists'
        } else if (window.location.pathname.includes("/list")) {
            reset_user_session();
            pageTitle = pageTitle + " - List";
            window.history.replaceState(null, "List", window.location.pathname);
            var categoryID = window.location.pathname.split("/")[2];
            activeComponent = <List id={categoryID}/>;
            activeMenu = 'lists'
        } else if (window.location.pathname === "/reminders") {
            reset_user_session();
            pageTitle = pageTitle + " - Reminders";
            window.history.replaceState(null, "Reminders", window.location.pathname);
            activeComponent = <Reminders mode="current"/>;
            activeMenu = 'current'
        } else if (window.location.pathname === "/reminders/late") {
            reset_user_session();
            pageTitle = pageTitle + " - Overdue reminders";
            window.history.replaceState(null, "Overdue reminders", window.location.pathname);
            activeComponent = <Reminders mode="late"/>;
            activeMenu = 'late'
        } else if (window.location.pathname === "/reminders/upcoming") {
            reset_user_session();
            pageTitle = pageTitle + " - Upcoming reminders";
            window.history.replaceState(null, "Upcoming reminders", window.location.pathname);
            activeComponent = <Reminders mode="upcoming"/>;
            activeMenu = 'upcoming'
        } else if (window.location.pathname === "/activities") {
            reset_user_session();
            pageTitle = pageTitle + " - Activities - From me";
            window.history.replaceState(null, "Activities - From me", window.location.pathname);
            activeComponent = <ActivitiesList mode="owner"/>;
            activeMenu = 'fromMe'
        } else if (window.location.pathname === "/activities/invited") {
            reset_user_session();
            pageTitle = pageTitle + " - Activities - From others";
            window.history.replaceState(null, "Activities - From others", window.location.pathname);
            activeComponent = <ActivitiesList mode="assignee"/>;
            activeMenu = 'fromOthers'
        } else if (window.location.pathname.includes("/activity")) {
            reset_user_session();
            pageTitle = pageTitle + " - Activity";
            window.history.replaceState(null, "Activity", window.location.pathname);
            var itemID = window.location.pathname.split("/")[2];
            activeComponent = <Activity id={itemID}/>;
            activeMenu = ''
        } else if (window.location.pathname === "/contacts") {
            reset_user_session();
            pageTitle = pageTitle + " - Contacts";
            window.history.replaceState(null, "Contacts", window.location.pathname);
            activeComponent = <Assignees/>;
            activeMenu = 'contacts'
        } else if (window.location.pathname === "/awards") {
            reset_user_session();
            pageTitle = pageTitle + " - Awards & Achievements";
            window.history.replaceState(null, "Awards & Achievements", window.location.pathname);
            activeComponent = <Awards/>;
            activeMenu = 'awards'
        } else if (window.location.pathname === '/pricing') {
            reset_user_session();
            pageTitle = pageTitle + " - Pricing";
            window.history.replaceState(null, "Pricing", "/pricing");
            activeComponent = <Pricing/>;
        } else {
            reset_user_session();
            window.history.replaceState(null, "Dasbhoard", "/dashboard");
            activeComponent = <DashboardStats/>;
            activeMenu = 'dashboard'
            showTourLink = true;
        }
    }


    return (
        <div className="wrapper">
            <Helmet>
                <title>{pageTitle}</title>
                <link rel="stylesheet" type="text/css"
                      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900|Roboto+Slab:400,700"/>
                <link href="/dashboard/css/nucleo-icons.css" rel="stylesheet"/>
                <link href="/dashboard/css/nucleo-svg.css" rel="stylesheet"/>
                <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Round" rel="stylesheet"/>
                <link id="pagestyle" type="text/css" href="/dashboard/css/material-dashboard.css" rel="stylesheet"/>
                <link type="text/css" href="/dashboard/css/style.css" rel="stylesheet"/>
                <script src="/dashboard/js/core/popper.min.js"></script>
                <script src="/dashboard/js/core/bootstrap.min.js"></script>
            </Helmet>
            {showSidebar && <Sidebar activeMenu={activeMenu}/>}
            <main className="main-content border-radius-lg " id="main-panel">
                {showNavbar && <Navbar showTourLink={showTourLink}/>}
                <div className="container-fluid">
                    {activeComponent}
                </div>

                <Footer/>
            </main>

            <AddListModal/>
            <AddItemModal/>
            <AddAssigneeModal/>
            <PaymentModal/>
            <AddNudgeModal />
        </div>

    );

}
