import React  from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';

function App() {

    return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard />
                    </Route>
                    <Route exact path="/login">
                        <Dashboard />
                    </Route>
                    <Route exact path="/register">
                        <Dashboard />
                    </Route>
                    <Route exact path="/logout">
                        <Dashboard />
                    </Route>
                    <Route exact path="/resend-confirmation">
                        <Dashboard />
                    </Route>
                    <Route exact path="/confirmation">
                        <Dashboard />
                    </Route>
                    <Route exact path="/forgot-password">
                        <Dashboard />
                    </Route>
                    <Route exact path="/password/edit">
                        <Dashboard />
                    </Route>
                    <Route exact path="/resend-unlock">
                        <Dashboard />
                    </Route>
                    <Route exact path="/unlock">
                        <Dashboard />
                    </Route>
                    <Route exact path="/account">
                        <Dashboard />
                    </Route>
                    <Route path="/contacts">
                        <Dashboard />
                    </Route>
                    <Route path="/awards">
                        <Dashboard />
                    </Route>
                    <Route path="/reminders">
                        <Dashboard />
                    </Route>
                    <Route path="/reminders/late">
                        <Dashboard />
                    </Route>
                    <Route path="/reminders/overdue">
                        <Dashboard />
                    </Route>
                    <Route path="/activities">
                        <Dashboard />
                    </Route>
                    <Route path="/activities/invited">
                        <Dashboard />
                    </Route>
                    <Route path="/lists">
                        <Dashboard />
                    </Route>
                    <Route path="/list/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/activity/:id">
                        <Dashboard />
                    </Route>
                    <Route exact path="/activity_invitation">
                        <Dashboard />
                    </Route>
                    <Route exact path="/activity_reminder">
                        <Dashboard />
                    </Route>
                    <Route exact path="/pricing">
                        <Dashboard />
                    </Route>
                    <Route exact path="/privacy">
                        <Dashboard />
                    </Route>
                    <Route exact path="/terms">
                        <Dashboard />
                    </Route>
                </Switch>
            </BrowserRouter>
    );
}

export default App;