import React from "react";

export default function AssigneeItem(props) {

    const showEditAssigneeModal = () => {
        window.setAssigneeModalFormFields(props);

        document.getElementById('add-assignee-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit contact';
        document.getElementById('add-assignee-modal').style.display = "block";
    };

    return (

        <tr>
            <td>
                <div className="d-flex px-3 py-1">
                    <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">{props.data.contact}</h6>
                    </div>
                </div>
            </td>
            <td>
                <p className="text-sm font-weight-normal mb-0">{props.data.name}</p>
            </td>
            <td className="align-middle text-center text-sm">
                <p className="text-sm font-weight-normal mb-0">{props.data.num_tasks}</p>
            </td>
            <td className="align-middle text-center text-sm">
                <p className="text-sm font-weight-normal mb-0">{props.data.num_reminders}</p>
            </td>
            <td className="align-middle text-end">
                <button type="button" rel="tooltip" onClick={showEditAssigneeModal} title="Edit"
                        className='btn btn-muted btn-round settings-btn'>
                    <i className="material-icons">settings</i> Edit
                </button>
            </td>
        </tr>



    );
}