import React from "react";
import RemindersList from "./RemindersList";

export default function Reminders(props) {

    return (
        <div className="row mt-lg-4 mt-2 mb-4">
            <div className="card">
                <div className="card-header p-3">
                    <div className="d-flex mt-n2">
                        <div
                            className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-rose'}>
                            <i className="material-icons opacity-10">alarm</i>
                        </div>
                        <div className="ms-3 my-auto">
                            <h4 className="mb-0">
                                {props.mode === 'late' && "Overdue/late reminders"}
                                {props.mode === 'upcoming' && "Upcoming reminders"}
                                {props.mode === 'current' && "Current reminders"}
                            </h4>
                        </div>
                    </div>
                    <hr className="horizontal dark" />
                </div>
                <RemindersList mode={props.mode} />
            </div>
        </div>
    );
};

