import React from "react";

export default function Footer() {

    return (
        <footer className="footer">
            <div className="container-fluid">
            </div>
        </footer>
    )
}