import React, {useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";

export default function ReminderItem(props) {

    var reminder_date = props.mode !== 'upcoming' ? new Date(props.data.reminder_date).toDateString() : '';
    var due_date =  props.mode !== 'upcoming' ? new Date(props.data.due_date).toDateString() : '';
    var next_reminder = props.data.next_reminder ? new Date(props.data.next_reminder).toDateString() : '';

    const [reminderCompleted, updateReminderCompleted] = useState(props.data.status === 'completed' ? true : false);

    const onCheckReminder = (e) => {

        var checked = e.target.checked;
        var task_reminder_id = e.target.value;

        var prev_response = 'sent';
        var response = 'completed';
        if (!checked) {
            prev_response = 'completed';
            response = 'sent';
        }

        updateReminderCompleted(e.target.checked);
        var confirm_msg = "Confirm status change from " + prev_response + " to " + response;
        Swal.fire({
            icon: "question",
            title: "Are you sure?",
            text: confirm_msg,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                confirmReminderCompletion(task_reminder_id, response);
            } else {
                updateReminderCompleted(false);
            }
        })

    }

    const cancelReminder = () => {

        var task_reminder_id = props.data.id;
        var response = 'cancelled';
        var confirm_msg = "Confirm that you would like to cancel this reminder.";
        Swal.fire({
            icon: "question",
            title: "Are you sure?",
            text: confirm_msg,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                confirmReminderCompletion(task_reminder_id, response);
            } else {
                updateReminderCompleted(false);
            }
        })

    };

    const confirmReminderCompletion = (reminder_id, response) => {

        return axios
            .post(APIService.API_URL + "task_reminders/user_response",
                {"task_reminder_id": reminder_id, "response": response, "remindersMode": props.mode},
                {headers: {"Authorization": APIService.getHeaders().authorization}}
            ).then((response) => {
                if (response.data.errors) {
                    window.location.reload();
                } else {
                    props.updateList(response.data.user_reminders)
                    var resp_msg = "You have successfully updated this activity reminder.";
                    Swal.fire({
                        icon: 'success',
                        title: 'Well done.',
                        text: resp_msg,
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                }

            });

    }

    return (

        <li className="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-3">
            <div className="checklist-item checklist-item-primary ps-2 md-1 ms-md-3">
                <div
                    className={"d-flex align-items-center " + ((props.mode !== 'upcoming' && reminderCompleted) ? 'line-through' : '')}>
                    {props.mode !== 'upcoming' && <div className="rm-checkbox m-3 pt-3">
                        <input type="checkbox" name="list-color" id={"rm-chck-" + props.mode + "-" + props.index} value={props.data.id}
                               onChange={onCheckReminder} checked={reminderCompleted}/>
                        <label htmlFor={"rm-chck-" + props.mode + "-" + props.index}><span></span></label>
                    </div>}
                    {props.mode === 'upcoming' && <div className="rm-checkbox m-2 pt-2">
                        &nbsp;
                    </div>}
                    <h6 className="mb-0 text-dark text-sm">{props.data.title}</h6>
                    <div className="dropstart float-lg-end ms-auto d-none d-md-block">
                        <a href={"/activity/" + props.data.user_task_id} title="show"
                           className='btn btn-muted btn-round settings-btn'>
                            <i className="material-icons">launch</i> Show details
                        </a>
                    </div>
                    {props.mode !== 'upcoming' && <div className="dropstart float-lg-end d-none d-md-block">
                        <button type="button" rel="tooltip" onClick={cancelReminder} title="Edit" disabled={reminderCompleted ? true : false}
                                className={"btn btn-muted btn-round settings-btn "  + (reminderCompleted ? 'line-through' : '')}>
                            <i className="material-icons">cancel</i> Cancel
                        </button>
                    </div>}
                </div>
                <div className="d-block d-md-none">
                    <a href={"/activity/" + props.data.user_task_id} title="show"
                       className='btn btn-muted btn-round settings-btn'>
                        <i className="material-icons">launch</i> Show details
                    </a>
                    <button type="button" rel="tooltip" onClick={cancelReminder} title="Edit" disabled={reminderCompleted ? true : false}
                            className={"btn btn-muted btn-round settings-btn "  + (reminderCompleted ? 'line-through' : '')}>
                        <i className="material-icons">cancel</i> Cancel
                    </button>
                </div>
                <div className="d-flex align-items-center ms-3 ms-md-4 mt-3 ps-1">
                    {props.mode !== 'upcoming' && <div className="d-none d-md-block">
                        <h6 className="mb-0 text-sm">Reminder date</h6>
                        <span className="text-capitalize text-sm">{reminder_date}</span>
                    </div>}
                    {props.mode === 'upcoming' && <div className="d-md-block">
                        <h6 className="mb-0 text-sm">Next reminder</h6>
                        <span className="text-capitalize text-sm">{next_reminder}</span>
                    </div>}
                    {props.mode !== 'upcoming' && <div className="ms-1 ms-md-auto d-md-block">
                        <h6 className="mb-0 text-sm">Due date</h6>
                        <span className="text-capitalize text-sm">{due_date}</span>
                    </div>}
                    <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">From</h6>
                        <span className="text-sm">{props.data.from_contact}</span>
                    </div>
                    {props.mode !== 'upcoming' && <div className="ms-auto">
                        <h6 className="mb-0 text-sm">Status</h6>
                        <span className="badge badge-dot me-4 pt-0">
                           {props.data.status === 'completed' && <i className="bg-success"></i>}
                            {props.data.status === 'pending' && <i className="bg-warning"></i>}
                            {props.data.status === 'sent' && <i className="bg-warning"></i>}
                            <span className="text-dark text-sm">{props.data.status}</span>
                        </span>
                    </div>}
                    <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">Reminders sent</h6>
                        <span className="text-capitalize text-sm">{props.data.frequency}</span>
                    </div>

                    {props.mode === 'current' && <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">Next reminder</h6>
                        <span className="text-capitalize text-sm">{next_reminder}</span>
                    </div>}

                </div>
            </div>
            <hr className="horizontal dark mt-4 mb-0"/>
        </li>

    );
}