import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import axios from "axios";


export default function Awards() {

    const [loading, setLoading] = useState(true);
    const [awardsData, updateAwardsData] = useState();

    useEffect(() => {

        axios.get(APIService.API_URL + "user_awards/get",
            {headers: {"Authorization": APIService.getHeaders().authorization}})
            .then((response) => {

                if (response.data.errors) {
                    // do nothing
                } else {
                   updateAwardsData(response.data.user_awards);
                   setLoading(false);
                   console.log(awardsData)
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    return (
        <div>
            {awardsData &&
            <div className="row mt-2 mb-5">
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                           <div
                                className="icon icon-shape icon-lg bg-gradient-faded-info shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">emoji_events</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{awardsData.activities} pts</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">Habit Points</h6>
                            <span className="text-xs">For activities you have created or accepted</span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-warning shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">military_tech</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{awardsData.reminders} pts</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">Nudge Points</h6>
                            <span className="text-xs">For reminders and invitations from you</span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-danger shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">local_police</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{awardsData.contacts} pts</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">People Points</h6>
                            <span className="text-xs">For adding or being added as a contact</span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                           <div
                                className="icon icon-shape icon-lg bg-gradient-faded-success shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">verified</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{awardsData.completed_reminders} pts</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">Completion Points</h6>
                            <span className="text-xs">For successfully completing your reminders</span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                 <div className="card">
                    <div className="card-header mx-4 p-3 text-center">
                        <div
                            className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                            <i className="material-icons opacity-10">filter_3</i>
                            <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-success border border-white small py-1 px-2">{awardsData.three_day_streaks} times</span>
                        </div>
                    </div>
                    <div className="card-body pt-0 p-3 text-center">
                        <h6 className="text-center mb-0">3 day streaks</h6>
                        <span className="text-xs"></span>
                    </div>
                </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_5</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-danger border border-white small py-1 px-2">{awardsData.five_day_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">5 day streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_7</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-info border border-white small py-1 px-2">{awardsData.seven_day_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">7 day streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_4</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-warning border border-white small py-1 px-2">{awardsData.four_week_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">4 week streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_3</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-info border border-white small py-1 px-2">{awardsData.three_month_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">3 month streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_6</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-warning border border-white small py-1 px-2">{awardsData.six_month_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">6 month streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">auto_awesome_motion</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-success border border-white small py-1 px-2">{awardsData.twelve_month_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">12 month streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 mt-3">
                    <div className="card">
                        <div className="card-header mx-4 p-3 text-center">
                            <div
                                className="icon icon-shape icon-lg bg-gradient-faded-secondary shadow text-center rounded-circle position-relative">
                                <i className="material-icons opacity-10">filter_1</i>
                                <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-danger border border-white small py-1 px-2">{awardsData.year_streaks} times</span>
                            </div>
                        </div>
                        <div className="card-body pt-0 p-3 text-center">
                            <h6 className="text-center mb-0">Yearly streaks</h6>
                            <span className="text-xs"></span>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

