import React from "react";

export default function Sidebar(activeMenu) {

    const onClickDashboard = () => {
        window.location.assign("/dashboard");
    };

    var dashboardActive = "";
    var currentActive = "";
    var lateActive =  "";
    var upcomingActive = "";
    var listsActive = "";
    var contactsActive = "" ;
    var awardsActive = "";
    var fromMe = "";
    var fromOthers = "";

    var activitiesOpen = "";
    var remindersOpen = "";

    switch(activeMenu.activeMenu) {
        case 'dashboard':
            dashboardActive = "active";
            break;
        case 'lists':
            listsActive = "active";
            break;
        case 'current':
            currentActive = "active";
            remindersOpen = "show";
            break;
        case 'late':
            lateActive = "active";
            remindersOpen = "show";
            break;
        case 'upcoming':
            upcomingActive = "active";
            remindersOpen = "show";
            break;
        case 'contacts':
            contactsActive = "active";
            break;
        case 'awards':
            awardsActive = "active";
            break;
        case 'fromMe':
            fromMe = "active";
            activitiesOpen = "show";
            break;
        case 'fromOthers':
            fromOthers = "active";
            activitiesOpen = "show";
            break;
        default:
        // code block
    }

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs
        border-0 border-radius-xl my-3 fixed-start ms-3 bg-white" id="sidenav-main">
            <div className="sidenav-header">
                <span className="navbar-brand m-0">
                   <a href="/" >
                    <img src="/assets/Reminded-logo-blue.png" className="navbar-brand-img" alt="main_logo" />
                   </a>
                </span>
            </div>
            <div className="collapse navbar-collapse w-auto h-auto max-height-vh-100 h-100 mt-3" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className={'nav-item '+dashboardActive}>
                        <a className={"nav-link text-dark font-weight-bolder "+dashboardActive} href="/dashboard" >
                            <i className="material-icons-round opacity-10">dashboard</i>
                            <span className="sidenav-normal  ms-2  ps-1"> Dashboard </span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#reminders" className="nav-link text-dark font-weight-bolder"
                           aria-controls="dashboards" role="button" aria-expanded="false">
                            <i className="material-icons-round opacity-10">alarm</i>
                            <span className="nav-link-text ms-2 ps-1">My reminders</span>
                        </a>
                        <div className={"collapse " + remindersOpen} id="reminders">
                            <ul className="nav ">
                                <li className={'nav-item '+currentActive}>
                                    <a className={"nav-link text-dark "+currentActive} href="/reminders" >
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal  ms-2  ps-1"> Current </span>
                                    </a>
                                </li>
                                <li className={'nav-item '+lateActive}>
                                    <a className={"nav-link text-dark "+lateActive} href="/reminders/late">
                                        <span className="sidenav-mini-icon"> O </span>
                                        <span className="sidenav-normal  ms-2  ps-1"> Overdue/Late </span>
                                    </a>
                                </li>
                                <li className={'nav-item '+ upcomingActive}>
                                    <a className={"nav-link text-dark "+upcomingActive} href="/reminders/upcoming" >
                                        <span className="sidenav-mini-icon"> U </span>
                                        <span className="sidenav-normal  ms-2  ps-1"> Upcoming </span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#activities" className="nav-link text-dark font-weight-bolder"
                           aria-controls="reminders" role="button" aria-expanded="false">
                            <i className="material-icons-round opacity-10">task</i>
                            <span className="nav-link-text ms-2 ps-1">My activities</span>
                        </a>
                        <div className={"collapse " + activitiesOpen} id="activities">
                            <ul className="nav ">
                                <li className={'nav-item '+ fromMe}>
                                    <a className={"nav-link text-dark "+fromMe} href="/activities" >
                                        <span className="sidenav-mini-icon"> M </span>
                                        <span className="sidenav-normal  ms-2  ps-1"> Personal </span>
                                    </a>
                                </li>
                                <li className={'nav-item '+ fromOthers}>
                                    <a className={"nav-link text-dark "+fromOthers} href="/activities/invited" >
                                        <span className="sidenav-mini-icon"> O </span>
                                        <span className="sidenav-normal  ms-2  ps-1"> Invited </span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li className={'nav-item '+listsActive}>
                        <a className={"nav-link text-dark font-weight-bolder "+listsActive} href="/lists" >
                            <i className="material-icons-round opacity-10">checklist</i>
                            <span className="sidenav-normal  ms-2  ps-1"> Lists </span>
                        </a>
                    </li>
                    <li className={'nav-item '+contactsActive}>
                        <a className={"nav-link text-dark font-weight-bolder "+contactsActive} href="/contacts" >
                            <i className="material-icons-round opacity-10">groups</i>
                            <span className="sidenav-normal  ms-2  ps-1"> Contacts </span>
                        </a>
                    </li>
                    <li className={'d-none nav-item '+awardsActive}>
                        <a className={"nav-link text-dark font-weight-bolder "+awardsActive} href="/awards" >
                            <i className="material-icons-round opacity-10">emoji_events</i>
                            <span className="sidenav-normal  ms-2  ps-1"> My awards </span>
                        </a>
                    </li>

                </ul>

            </div>

        </aside>
    )
}