import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Select from "react-validation/build/select";
import Swal from 'sweetalert2';
import {WithContext as ReactTags} from 'react-tag-input';
import {isEmail, isMobilePhone} from "validator";
import flatpickr from "flatpickr";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field requires a value.
            </div>
        );
    }
};

function getFrequencyTimes(frequency){
    var freqTimes;
    switch(frequency) {
        case 'daily':
            freqTimes = 'days';
            break;
        case 'weekly':
            freqTimes = 'weeks';
            break;
        case 'fortnightly':
            freqTimes = 'fortnights';
            break;
        case 'monthly':
            freqTimes = 'months';
            break;
        case 'yearly':
            freqTimes = 'years';
            break;
        default:
            freqTimes = 'days';
    }
    return freqTimes;
}

export default function AddItemModal() {

    window.addEventListener('load', function load() {

        flatpickr(".datepicker", {onChange: onChangeStartDate, disableMobile: true, dateFormat: "Y-m-d", minDate: "today", maxDate: new Date().fp_incr(30)});

        [...document.getElementsByClassName("ReactTags__tagInputField")].forEach((el) => {
            el.parentElement.parentElement.classList.add("form-control");
            el.parentElement.parentElement.classList.add("d-flex");
            el.parentElement.parentElement.classList.add("flex-wrap");

        });

        if (!document.getElementById("add-assignee-tag-btn")) {
            var assigneesInput = document.getElementById('form-add-item-assignees');
            const asnBt = document.createElement('button');
            asnBt.type = "button";
            asnBt.setAttribute("id", "add-assignee-tag-btn");
            asnBt.classList.add("ReactTags__add");
            asnBt.innerHTML = '+ Add';
            assigneesInput.parentNode.appendChild(asnBt);

            asnBt.onclick = function (e) {
                var val = e.target.parentElement.getElementsByTagName('input')[0].value.trim();
                var assn = {id: val, text: val};
                e.target.parentElement.getElementsByTagName('input')[0].value = '';
                window.handleAssigneesAddition(assn);
            };
        }

        if (!document.getElementById("add-tag-tag-btn")) {
            var tagsInput = document.getElementById('form-add-item-tags');
            const tagBt = document.createElement('button');
            tagBt.type = "button";
            tagBt.setAttribute("id", "add-tag-tag-btn");
            tagBt.classList.add("ReactTags__add");
            tagBt.innerHTML = '+ Add';
            tagsInput.parentNode.appendChild(tagBt);

            tagBt.onclick = function (e) {
                var val = e.target.parentElement.getElementsByTagName('input')[0].value.trim();
                var tag = {id: val, text: val};
                e.target.parentElement.getElementsByTagName('input')[0].value = '';
                window.handleTagAddition(tag);
            };
        }

    });

    const form = useRef();
    const checkBtn = useRef();

    const [taskID, setTaskID] = useState("");
    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date().toDateString());
    const [frequency, setFrequency] = useState("daily");
    const [frequencyTimes, setFrequencyTimes] = useState("days");
    const [numTimes, setNumTimes] = useState("10");
    const [tasklist, setTasklist] = useState("");
    const [description, setDescription] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    var userTasklists = APIService.getTasklists();

    const [tags, updateTags] = useState([]);

    var tagSuggestions = [];
    var tagsObj = APIService.getTags();
    if (tagsObj) {
        for (var i = 0; i < tagsObj.length; i++) {
            tagSuggestions = [...tagSuggestions, {
                id: tagsObj[i].name,
                text: tagsObj[i].name
            }];
        }
    }

    function handleTagDelete(i) {
        updateTags(tags.filter((tag, index) => index !== i));
    }

    function handleTagAddition(tag) {
        updateTags([...tags, tag]);
    }

    window.handleTagAddition = handleTagAddition;

    function handleTagDrag(tag, currPos, newPos) {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        updateTags(newTags);
    }

    var initialAssignee = [];
    var currUser = APIService.getCurrentUser();
    var limitContacts = 3;
    var limitActivities = 5;
    var active_num_tasks = 0;
    if (currUser) {
        initialAssignee = [{
            id: currUser.user.email,
            text: currUser.user.email,
        }];

        limitContacts = currUser.user.subscription_plan.contacts;
        limitActivities = currUser.user.subscription_plan.activities;

        var active_tasks = APIService.getActiveTasks();
        if(active_tasks){
            active_num_tasks = active_tasks;
        }
    }

    const [assignees, updateAssignees] = useState(initialAssignee);

    var assigneesSuggestions = [];
    var assigneesObj = APIService.getAssignees();
    if (assigneesObj) {
        for (var ii = 0; ii < assigneesObj.length; ii++) {
            assigneesSuggestions = [...assigneesSuggestions, {
                id: assigneesObj[ii].contact,
                text: assigneesObj[ii].contact
            }];
        }
    }

    function handleAssigneesDelete(i) {
        document.getElementById("item-contacts-count").innerText = assignees.length - 1;
        if ((assignees.length - 1) < limitContacts) {
            var assigneesInput = document.getElementById('form-add-item-assignees');
            assigneesInput.parentNode.classList.remove('d-none');
        }
        updateAssignees(assignees.filter((assignee, index) => index !== i));
    }

    function handleAssigneesAddition(assignee) {
        if (!isEmail(assignee.text) && !isMobilePhone(assignee.text)) {
            document.getElementById("assignees-msg").classList.remove("hidden");
            document.getElementById('form-add-item-assignees').value = assignee.text;
            return;
        }
        document.getElementById("assignees-msg").classList.add("hidden");
        document.getElementById("item-contacts-count").innerText = assignees.length + 1;
        if ((assignees.length + 1) >= limitContacts) {
            var assigneesInput = document.getElementById('form-add-item-assignees');
            assigneesInput.parentNode.classList.add('d-none');
        }
        updateAssignees([...assignees, assignee]);
    }

    window.handleAssigneesAddition = handleAssigneesAddition;

    function handleAssigneesDrag(assignee, currPos, newPos) {
        const newAssignees = assignees.slice();
        newAssignees.splice(currPos, 1);
        newAssignees.splice(newPos, 0, assignee);
        updateAssignees(newAssignees);
    }

    const KeyCodes = {
        comma: 188,
        enter: 13,
        space: 32
    };

    const delimiters = [KeyCodes.enter, KeyCodes.comma, KeyCodes.space];

    const descMaxChars = 500;
    const titleMaxChars = 150;

    const onChangeTaskID = (e) => {
        setTaskID(e.target.value);
    };

    const onChangeStartDate = (e) => {
        setStartDate(new Date(e[0]).toDateString());
    };

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
        document.getElementById("item-title-char-count").innerText = e.target.value.length;
    };

    const onChangeFrequency = (e) => {
        setFrequency(e.target.value);
        setFrequencyTimes(getFrequencyTimes(e.target.value));
    };

    const onChangeNumTimes = (e) => {
        setNumTimes(e.target.value);
    };

    const onChangeTasklist = (e) => {
        setTasklist(e.target.value);
    };

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("item-description-char-count").innerText = e.target.value.length;
    };

    const onClickModalClose = () => {

        var modal = document.getElementById('add-item-modal');
        modal.style.display = "none";

        document.getElementById("item-title-char-count").innerText = '0';
        document.getElementById("item-description-char-count").innerText = '0';


        setTaskID("");
        setTitle("");
        setStartDate(new Date().toDateString());
        setFrequency("daily");
        setFrequencyTimes('days');
        setNumTimes("10");
        setTasklist("My activities");
        setDescription("");

        updateTags([]);
        var numContacts = 1;
        if (currUser) {
            updateAssignees([{
                id: currUser.user.email,
                text: currUser.user.email,
            }]);

        } else {
            updateAssignees([]);
            numContacts = 0;
        }
        document.getElementById("item-contacts-count").innerText = numContacts;

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickItemDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Your activity and all its history will be deleted.',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteTasklistItem(taskID);
                if (window.location.pathname.includes("/activity/")) {
                    window.location.assign("/lists")
                }
            }
        });
    }

    const setTaskModalFormFields = (data) => {

        document.getElementById("item-title-char-count").innerText = data.title.length;
        document.getElementById("item-description-char-count").innerText = data.description.length;

        setTaskID(data.id);
        setTitle(data.title);
        setStartDate(new Date(data.start_date).toDateString());
        setFrequency(data.frequency);
        setFrequencyTimes(getFrequencyTimes(data.frequency));
        setNumTimes(data.num_times);
        setTasklist(data.user_tasklist.title);
        setDescription(data.description);

        var taskTags = [];
        if (data.task_tags) {
            for (var ij = 0; ij < data.task_tags.length; ij++) {
                taskTags = [...taskTags, {
                    id: data.task_tags[ij].name,
                    text: data.task_tags[ij].name
                }];
            }
        }

        updateTags(taskTags);

        var taskAssigns = [];
        var numContacts = 0;
        if (data.task_assignees) {
            numContacts = data.task_assignees.length;
            for (var ik = 0; ik < data.task_assignees.length; ik++) {
                taskAssigns = [...taskAssigns, {
                    id: data.task_assignees[ik].user_assignee.contact,
                    text: data.task_assignees[ik].user_assignee.contact
                }];
            }
        }
        updateAssignees(taskAssigns);
        document.getElementById("item-contacts-count").innerText = numContacts;

        setLoading(false);
        setSuccessful(false);
        setMessage("");


    }

    window.setTaskModalFormFields = setTaskModalFormFields;

    const setTaskModalListField = (data) => {
        setTasklist(data.title);
    }

    window.setTaskModalListField = setTaskModalListField;

    const addNewItem = (e) => {

        e.preventDefault();

        // Check if new activity  is within subscription limit
        /* BEGIN */
        if (taskID === "") {
            if (active_num_tasks >= limitActivities) {
                setMessage("You have reached your limit of active activities (" + limitActivities + ").");
                return;
            } else {
                setMessage("");
            }
        }
        /* END */

        var start_date = document.getElementById("form-add-item-start-date").value;

        var assnArray = assignees;
        if (assignees.length === 0) {
            assnArray = [{
                id: currUser.user.email,
                text: currUser.user.email,
            }];
        }

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "user_tasks/add";
            var task = {
                "user_task_id": taskID,
                "task": {
                    "title": title, "description": description,
                    "start_date": start_date, "frequency": frequency,
                    "num_times": numTimes, "user_tasklist": tasklist,
                    "status": "", "assignees": assnArray, "tags": tags
                }
            }

            if (taskID !== "") {
                api_endpoint = "user_tasks/update";
            }

            axios.post(APIService.API_URL + api_endpoint, task,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    if (window.location.pathname.includes("/activity/")) {
                        window.updateActivityData(response.data.user_task);
                    } else {
                        APIService.resetTasklists();
                        APIService.resetTasklistItems();
                    }
                    onClickModalClose();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your activity has been saved.',
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }


    return (
        <div className="modal" tabIndex="-1" role="dialog" id="add-item-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewItem} className="navbar-form" id="form-add-item-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">Add an activity</h4>
                            <button className="close-btn btn btn-icon" type="button" onClick={onClickModalClose}>
                                <i className="material-icons">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="task-id" value={taskID} onChange={onChangeTaskID}/>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="title" className="text-normal text-dark">Start with a title
                                        <Input type="text" id="form-add-item-title" name="title"
                                               className="form-control" value={title}
                                               maxLength={titleMaxChars} placeholder="Title here..."
                                               onChange={onChangeTitle}
                                               validations={[required]}/>

                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="item-title-char-count">0</span> / {titleMaxChars} chars.
                                </div>
                            </div>

                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="assignees" className="text-normal text-dark">Add contacts (email or mobile numbers)
                                        <ReactTags id="form-add-item-assignees"
                                                   tags={assignees}
                                                   suggestions={assigneesSuggestions}
                                                   handleDelete={handleAssigneesDelete}
                                                   handleAddition={handleAssigneesAddition}
                                                   handleDrag={handleAssigneesDrag}
                                                   delimiters={delimiters}
                                                   placeholder="Type and click add"
                                                   inline={true}
                                                   allowUnique={true}
                                                   minQueryLength={3}
                                        />
                                    </label>
                                    <div id="assignees-msg" className="text-primary hidden text-sm">Enter valid email or
                                        phone number.
                                    </div>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="item-contacts-count">1</span> / {limitContacts} contacts. <a
                                    href="/pricing">Upgrade</a> to increase limit.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-div">
                                        <div className="input-group input-group-outline">
                                            <label htmlFor="start-date" className="text-normal text-dark">When should it
                                                start?
                                                <Input type="text" id="form-add-item-start-date" name="start-date"
                                                       className="datepicker form-control" placeholder="yyyy-mm-dd"
                                                       value={startDate}
                                                       validations={[required]}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-div">
                                        <div className="input-group input-group-outline">
                                            <label htmlFor="frequency" className="text-normal text-dark">Send reminders...
                                                <Select id="form-add-item-frequency" className="form-control"
                                                        name="frequency"
                                                        value={frequency} onChange={onChangeFrequency}
                                                        validations={[required]}>
                                                    <option value="daily">Daily</option>
                                                    <option value="weekly">Weekly</option>
                                                    <option value="fortnightly">Fortnightly</option>
                                                    <option value="monthly">Monthly</option>
                                                    <option value="yearly">Yearly</option>
                                                </Select>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-div">
                                        <div className="input-group input-group-outline">
                                            <label htmlFor="repeat-times" className="text-normal text-dark">For how many {frequencyTimes}?
                                                <Input type="number" min="1" step="1" id="form-add-item-repeat-times"
                                                       name="repeat-times"
                                                       list="repeat-times-datalist"
                                                       className="form-control" placeholder="10"
                                                       value={numTimes} onChange={onChangeNumTimes}
                                                       validations={[required]}/>
                                                <datalist id="repeat-times-datalist">
                                                    <option>1</option>
                                                    <option>5</option>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                </datalist>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-div">
                                        <div className="input-group input-group-outline">
                                            <label htmlFor="tasklist" className="text-normal text-dark">Add it to a list
                                                <Input type="text" id="form-add-item-tasklist" name="tasklist"
                                                       list="tasklists-datalist" maxLength={titleMaxChars}
                                                       className="form-control" placeholder="Select or type to add new"
                                                       value={tasklist} onChange={onChangeTasklist}/>
                                                <datalist id="tasklists-datalist">
                                                    {userTasklists && userTasklists.map((c, index, prop) => (
                                                        <option key={c.id} data-id={c.title}>{c.title}</option>
                                                    ))}
                                                </datalist>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-div d-none">
                                        <div className="input-group input-group-outline">
                                            <label htmlFor="tags" className="text-normal text-dark">Add some tags &
                                                labels
                                                <ReactTags id="form-add-item-tags"
                                                           tags={tags}
                                                           suggestions={tagSuggestions}
                                                           handleDelete={handleTagDelete}
                                                           handleAddition={handleTagAddition}
                                                           handleDrag={handleTagDrag}
                                                           delimiters={delimiters}
                                                           maxLength={15}
                                                           placeholder="Type and click add"
                                                           inline={true}
                                                           allowUnique={true}/>
                                            </label>
                                        </div>
                                    </div>

                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="description" className="text-normal text-dark">Any additional
                                        details?
                                        <Textarea id="form-add-item-description" name="description"
                                                  value={description} onChange={onChangeDescription}
                                                  maxLength={descMaxChars} className="form-control" rows="4"
                                                  placeholder="Add notes here..."/>
                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="item-description-char-count">0</span> / {descMaxChars} chars.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {taskID && <button type="button" className="btn btn-outline-danger" data-dismiss="modal"
                                               onClick={onClickItemDelete}>Delete
                            </button>}
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}