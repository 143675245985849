import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="text-primary" role="alert">
                This is not a valid email.
            </div>
        );
    }
};



const UpdateProfile = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    let current_user = APIService.getCurrentUser();

    const [firstName, setFirstName] = useState(current_user.user.first_name);
    const [lastName, setLastName] = useState(current_user.user.last_name);

    const [email, setEmail] = useState(current_user.user.email);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangeFirstName = (e) => {
        const firstName = e.target.value;
        setFirstName(firstName);
    };

    const onChangeLastName = (e) => {
        const lastName = e.target.value;
        setLastName(lastName);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleUpdateAccount = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            const user = {
                'user': {
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    current_password: password
                }};

            axios.put(APIService.AUTH_URL + "signup", user,
                { headers: { "Authorization": APIService.getHeaders().authorization} }).
            then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    sessionStorage.setItem("user", JSON.stringify(response.data));
                    window.setProfileCurrentUser(APIService.getCurrentUser());
                    setLoading(false);
                    setSuccessful(true);
                    setPassword("");
                    setMessage("");
                    setPasswordShown(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your  account has been updated.',
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });

        } else {
            setLoading(false);
        }
    };

    return (
        <div className="card mt-4" id="update-profile">
            <div className="card-header">
                <h5>Update your profile</h5>
            </div>
            <div className="card-body pt-0">
                <Form onSubmit={handleUpdateAccount} ref={form}>

                        {message && (
                            <div className="form-group">
                                <div
                                    className={
                                        successful ? "alert alert-success text-white" : "text-primary mb-3"
                                    }
                                    role="alert"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: message }} />
                                </div>
                            </div>
                        )}

                        <div className="input-group input-group-outline">
                            <label className="text-normal text-dark">Email
                            <Input type="email" className="form-control"
                                   placeholder="shay@example.com"
                                   name="email" value={email}
                                   onChange={onChangeEmail}
                                   validations={[required, validEmail]}
                            />
                            </label>
                        </div>
                        <div className="input-group input-group-outline">
                            <label className="text-normal text-dark">First name
                            <Input type="text" className="form-control"
                                   placeholder="Happy"
                                   name="firstName" value={firstName}
                                   onChange={onChangeFirstName}
                                   validations={[]}
                            />
                            </label>
                        </div>
                        <div className="input-group input-group-outline">
                            <label className="text-normal text-dark">Last name
                            <Input type="text" className="form-control"
                                   placeholder="Happier"
                                   name="lastName" value={lastName}
                                   onChange={onChangeLastName}
                                   validations={[]}
                            />
                            </label>
                        </div>
                        <div className="input-group input-group-outline">
                            <label className="text-normal text-dark">Current password
                            <Input type={passwordShown ? "text" : "password"}
                                   className="form-control" placeholder="Password"
                                   name="password" id="password"
                                   value={password}
                                   onChange={onChangePassword}
                                   validations={[required]}
                            />
                                <i className="material-icons show-password" onClick={togglePassword}>visibility</i>
                            </label>
                        </div>

                        <div className="input-group input-group-outline">

                            <button className="btn btn-lg bg-gradient-primary btn-lg mt-4 mb-0" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Submit</span>
                            </button>
                        </div>
                        <CheckButton style={{display: "none"}} ref={checkBtn}/>

                    </Form>
            </div>
        </div>
    );
};

export default UpdateProfile;
