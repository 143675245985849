import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import AssigneeItem from "./AssigneeItem";
import axios from "axios";


export default function Assignees() {

    const [loading, setLoading] = useState(true);
    const [assigneesData, updateAssigneesData] = useState();

    window.updateAssigneesData = updateAssigneesData;

    useEffect(() => {

        axios.get(APIService.API_URL + "user_assignees/get",
            {headers: {"Authorization": APIService.getHeaders().authorization}})
            .then((response) => {

                if (response.data.errors) {
                    // do nothing
                } else {
                    sessionStorage.setItem("user_assignees", JSON.stringify(response.data.user_assignees));
                    updateAssigneesData(APIService.getAssignees());
                    setLoading(false);
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    const onClickAddNewAssignee = () => {
        document.getElementById('add-assignee-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a contact';
        document.getElementById('add-assignee-modal').style.display = "block";
    };

    return (
        <div className="row mt-lg-4 mt-2 mb-4">
            <div className="card">
                <div className="card-header pb-0">
                    <div className="d-flex mt-n2">
                        <div
                            className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-rose'}>
                            <i className="material-icons opacity-10">groups</i>
                        </div>
                        <div className="ms-3 my-auto">
                            <h4 className="mb-0">Contacts</h4>
                        </div>
                        <div className="ms-auto d-none d-md-block">
                            <div className="ms-auto my-auto">
                                <button onClick={onClickAddNewAssignee} className="btn bg-gradient-primary btn-sm mb-0"
                                        target="_blank">+&nbsp; Add new
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {assigneesData && assigneesData.length > 0 && <div className="table-responsive p-0">
                        <table className="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Contact
                                </th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Activities
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Reminders
                                </th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {assigneesData.map((c, index) => (
                                <AssigneeItem
                                    data={c}
                                    key={index}
                                    index={index}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>}
                    {(!assigneesData || assigneesData.length < 1) &&
                    <p>You have no contacts added.</p>
                    }
                </div>
            </div>
        </div>
    );
};

