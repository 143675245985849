import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import ReminderItem from "./ReminderItem";

export default function RemindersList(props) {

    const [remindersData, updateRemindersData] = useState();
    const [loading, setLoading] = useState(true);

    var api_url = "task_reminders/get_current_reminders";
    if(props.mode === 'late'){
        api_url = "task_reminders/get_late_reminders";
    } else if(props.mode === 'upcoming'){
        api_url = "task_reminders/get_upcoming_reminders";
    }

    useEffect(() => {
        axios.get(APIService.API_URL +  api_url, {
            headers: {"Authorization": APIService.getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateRemindersData(response.data.user_reminders);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, []);


    if (loading) {
        return <div/>;
    }

    return (
        <div className="card-body">
                    {remindersData.length > 0 && <ul className="list-group list-group-flush">

                        {remindersData.map((c, index) => (
                            <ReminderItem
                                data={c}
                                key={c.id + "-" + index}
                                index={index}
                                mode={props.mode}
                                updateList = {updateRemindersData}
                            />
                        ))}

                    </ul>}
                    {remindersData.length < 1 &&
                    <p>You have no
                        {props.mode === 'late' && " overdue/late "}
                        {props.mode === 'upcoming' && " upcoming "}
                        {props.mode === 'current' && " current "}
                        reminders.</p>
                    }

                </div>

    );
};

