import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import {Bar} from 'react-chartjs-2'
import Swal from "sweetalert2";

export default function Activity(id) {

    const activityID = id.id;
    const [activityData, updateActivityData] = useState();
    const [activityMode, updateActivityMode] = useState();
    const [loading, setLoading] = useState(true);

    window.activeActivityID = activityID;
    window.updateActivityData = updateActivityData;

    useEffect(() => {
        axios.get(APIService.API_URL + "user_tasks/get_by_id", {
            params: {"id": id.id},
            headers: {"Authorization": APIService.getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                if(response.data.user_task) {
                    updateActivityData(response.data.user_task);
                    updateActivityMode(response.data.mode)
                    setLoading(false);
                }
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, []);

    const showEditItemModal = () => {
        window.setTaskModalFormFields(activityData);

        document.getElementById('add-item-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit activity';
        document.getElementById('add-item-modal').style.display = "block";
    };

    const resendInvitations = () => {

        Swal.fire({
            icon: 'info',
            title: 'Are you sure?',
            text: 'Resend invites to contacts still pending.',
            showCancelButton: true,
            confirmButtonText: 'Send',
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            if (result.isConfirmed) {
                APIService.resendTaskInvitations(activityID);
            }
        });

    }

    const showAddNudgeModal = () => {

        document.getElementById('add-nudge-modal').style.display = "block";
    };

    if (loading) {
        return <div/>;
    }

    var total_assignees = 0;
    var total_invitations_accepted = 0;
    var total_reminders = 0;
    var total_completions = 0;
    var total_late_completions = 0;

    var chartLabels = [];
    var remindersStats = [];
    var completionsStats = [];
    var lateCompletionsStats = [];

    if(activityMode === 'owner') {
        total_assignees = activityData.task_assignees.length;
        for (var i = 0; i < activityData.task_assignees.length; i++) {
            if (activityData.task_assignees[i].status === "accepted") {
                total_invitations_accepted++

                chartLabels = [...chartLabels, activityData.task_assignees[i].user_assignee.contact];
                remindersStats = [...remindersStats, activityData.task_assignees[i].num_reminders];
                completionsStats = [...completionsStats, activityData.task_assignees[i].num_completions - activityData.task_assignees[i].num_late_completions];
                lateCompletionsStats = [...lateCompletionsStats, activityData.task_assignees[i].num_late_completions];
            }
            total_reminders = total_reminders + activityData.task_assignees[i].num_reminders;
            total_completions = total_completions + activityData.task_assignees[i].num_completions;
            total_late_completions = total_late_completions + activityData.task_assignees[i].num_late_completions;


        }
    } else {
        total_reminders = activityData.num_reminders;
        total_completions = activityData.num_completions;
        total_late_completions = activityData.num_late_completions;

        chartLabels = [activityData.to_contact];
        remindersStats = [activityData.num_reminders];
        completionsStats = [activityData.num_completions - activityData.num_late_completions];
        lateCompletionsStats = [activityData.num_late_completions];
    }


    var percent_completions = 0;
    if (total_reminders > 0) {
        percent_completions = (total_completions / total_reminders * 100).toFixed(2);
    }

    var percent_late_completions = 0;
    if (total_completions > 0) {
        percent_late_completions = (total_late_completions / total_completions * 100).toFixed(2);
    }

    var start_date = new Date(activityData.start_date).toDateString();
    var end_date = new Date(activityData.end_date).toDateString();
    var last_reminder = activityData.last_reminder ? new Date(activityData.last_reminder).toDateString() : '';
    var next_reminder = activityData.next_reminder ? new Date(activityData.next_reminder).toDateString() : '';



    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Reminders',
                data: remindersStats,
                backgroundColor: '#c2dede',
                stack: 'Stack 1',
            },
            {
                label: 'Completions',
                data: completionsStats,
                backgroundColor: '#f5f1cb',
                stack: 'Stack 0',
            },
            {
                label: 'Late completions',
                data: lateCompletionsStats,
                backgroundColor: '#facdd4',
                stack: 'Stack 0',
            },

        ]
    };

    var bar_chart_config =  {
        type: 'bar',
        data: data,
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Chart.js Bar Chart - Stacked'
                },
            },
            responsive: true,
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true
                }
            }
        }
    };

    return (
        <div className="mb-3">
            <div className="row mt-lg-4 mt-2 mb-4">
                <div className="card">
                    <div className="card-header p-3">
                        <div className="d-flex mt-n2">
                            <div
                                className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-rose'}>
                                <i className="material-icons opacity-10">task</i>
                            </div>
                            <div className="ms-3 my-auto">
                                <h4 className="mb-0">{activityData.title}</h4>
                            </div>
                            {activityMode === 'owner' && <div className="ms-auto">
                                <button className="btn btn-sm bg-gradient-dark m-3" onClick={showEditItemModal}>Edit
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 mt-sm-0 mt-4">
                    <div className="card">
                        <div className="card-body p-3 position-relative">
                            <div className="row">
                                <div className="col-7 text-start">
                                    <p className="text-sm mb-1 font-weight-bold">Reminders sent</p>
                                    <h5 className="font-weight-bolder mb-0">
                                        {total_reminders}
                                    </h5>
                                    <span
                                        className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">{total_assignees}
                                        <span
                                            className="font-weight-normal text-secondary"> contacts</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mt-sm-0 mt-4">
                    <div className="card">
                        <div className="card-body p-3 position-relative">
                            <div className="row">
                                <div className="col-7 text-start">
                                    <p className="text-sm mb-1 font-weight-bold">Completions</p>
                                    <h5 className="font-weight-bolder mb-0">
                                        {total_completions}
                                    </h5>
                                    <span
                                        className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">{percent_completions}% <span
                                        className="font-weight-normal text-secondary"> of reminders</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mt-sm-0 mt-4">
                    <div className="card">
                        <div className="card-body p-3 position-relative">
                            <div className="row">
                                <div className="col-7 text-start">
                                    <p className="text-sm mb-1 font-weight-bold">Late completions</p>
                                    <h5 className="font-weight-bolder mb-0">
                                        {total_late_completions}
                                    </h5>
                                    <span
                                        className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">{percent_late_completions}% <span
                                        className="font-weight-normal text-secondary"> of completions</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-4 col-sm-6">
                    <div className="card h-100">
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="mb-0">Details</h6>
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <ul className="list-group">
                                {activityMode === 'assignee' && <li className="list-group-item border-0 ps-0 pt-0 text-sm "><strong
                                    className="text-dark ">Activity by:</strong> &nbsp; {activityData.from_contact}
                                </li>}
                                {activityMode === 'assignee' && <li className="list-group-item border-0 ps-0 text-sm"><strong
                                    className="text-dark ">Invitation to:</strong> &nbsp; {activityData.to_contact}
                                </li>}
                                <li className="list-group-item border-0 ps-0 text-sm text-capitalize"><strong
                                    className="text-dark ">Reminders sent:</strong> &nbsp; {activityData.frequency}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm"><strong
                                    className="text-dark">Start
                                    date:</strong> &nbsp; {start_date}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm"><strong
                                    className="text-dark">End
                                    date:</strong> &nbsp; {end_date}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm"><strong
                                    className="text-dark">Last
                                    reminder:</strong> &nbsp; {last_reminder}
                                </li>
                                <li className="list-group-item border-0 ps-0 text-sm"><strong
                                    className="text-dark">Next
                                    reminder:</strong> &nbsp; {next_reminder}
                                </li>
                                {activityMode === 'owner' && <li className="d-none list-group-item border-0 ps-0 pb-0">
                                    <strong className="text-dark text-sm">Tags:</strong> &nbsp;
                                    {activityData.task_tags.map((c, index, prop) => (
                                        <span key={c.id}
                                              className="ReactTags__tag p-1 text-sm text-bold d-inline-flex">{c.name}</span>
                                    ))}
                                </li>}
                                <li className="list-group-item border-0 ps-0 text-sm "><strong
                                    className="text-dark">Notes:</strong>&nbsp;
                                    <div className="notes-box">{activityData.description}</div>
                                </li>
                            </ul>
                        </div>
                        <div className="card-footer pt-0 pb-0 p-3 d-flex align-items-center">

                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-sm-6 mt-sm-0 mt-4">
                    <div className="card">
                        <div className="card-header pb-0 p-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="mb-0">Progress</h6>
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <div className="chart">
                                <Bar data={data} options={bar_chart_config}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0 mb-2">
                            <div className="d-md-flex mt-n2">
                                <h6>Team/Contacts</h6>
                                <div className="ms-auto">
                                    {activityMode === 'owner' && <button className="btn btn-sm bg-gradient-primary m-2"
                                                                         onClick={showAddNudgeModal}> Send message
                                    </button>}
                                    {activityMode === 'owner' && <button className="btn btn-sm bg-gradient-dark m-2"
                                            onClick={resendInvitations}> Resend invitations
                                    </button>}

                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Team
                                            member
                                        </th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Reminders</th>
                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Completions</th>
                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Late
                                            completions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {activityData.task_assignees.map((c, index, prop) => (
                                        <tr key={c.id}>
                                            <td>
                                                <div className="d-flex px-3 py-1">
                                                    <div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{c.user_assignee.contact}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="text-sm font-weight-normal mb-0">{c.user_assignee.name}</p>
                                            </td>
                                            <td>
                                                <span className="badge badge-dot me-4">
                                                    {c.status === 'accepted' && <i className="bg-success"></i>}
                                                    {c.status === 'invited' && <i className="bg-warning"></i>}
                                                    {c.status === 'rejected' && <i className="bg-danger"></i>}
                                                    <span className="text-dark text-sm">{c.status}</span>
                                                </span>
                                            </td>
                                            <td className="align-middle text-center text-sm">
                                                <p className="text-sm font-weight-normal mb-0">{c.num_reminders}</p>
                                            </td>
                                            <td className="align-middle text-center text-sm">
                                                <p className="text-sm font-weight-normal mb-0">{c.num_completions}</p>
                                            </td>
                                            <td className="align-middle text-center text-sm">
                                                <p className="text-sm font-weight-normal mb-0">{c.num_late_completions}</p>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

