import React from "react";
import CookieConsent from "react-cookie-consent";

export default function CookieConsentComponent() {

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="cookie-consent"
            style={{background: "#2B373B"}}
            buttonStyle={{color: "#4e503b", fontSize: "13px", marginRight: "100px"}}
            expires={150}>
            This site uses cookies to monitor traffic and enhance your user experience.
        </CookieConsent>
    );

}