import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import CookieConsentComponent from "./CookieConsentComponent";

export default function Home() {

    window.addEventListener('load', function load() {
        document.body.classList.remove('hidden');
        const loader = document.getElementById('loader');
        setTimeout(function () {
            loader.classList.add('fadeOut');
        }, 300);
    });

    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="/home/assets/css/animate.css"/>
                <link rel="stylesheet" href="/home/assets/css/tiny-slider.css"/>
                <link rel="stylesheet" href="/home/assets/css/swiper.min.css"/>
                <link rel="stylesheet" href="/home/assets/css/glightbox.min.css"/>
                <link rel="stylesheet" href="/home/assets/css/LineIcons.2.0.css"/>
                <link rel="stylesheet" href="/home/assets/css/bootstrap-5.0.5-alpha.min.css"/>
                <link rel="stylesheet" href="/home/assets/css/style.css"/>
                <link rel="stylesheet" href="/home/assets/css/rd-custom-style.css"/>
            </Helmet>

            <header className="header_area">
                <div id="header_navbar" className="header_navbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="/">
                                        <img id="logo" src="/assets/Reminded-logo.png" alt="Logo" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                            aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" className="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <a className="page-scroll active" href="#home">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#how-work">How it Works</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#feature">Features</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#screenshots">Solutions</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#testimonials">Happy Users</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="page-scroll" href="#pricing">Pricing</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/register" className="btn-hover price-btn main-btn">Sign up</a>
                                            </li>
                                            <li className="nav-item sign-in">
                                                <a href="/login">Sign in</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section id="home" className="hero-area bg_cover">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="hero-content">
                                <h2 className="wow fadeInUp" data-wow-delay=".2s">Automated reminders for groups.</h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">Use Reminded to schedule and track automated reminders for groups via email or SMS. </p>
                                <p className="wow fadeInUp" data-wow-delay=".6s">Use it at home, at work and for business. With friends, your team and clients.</p>
                                <div className="hero-btns d-none ">
                                    <a href="/register" className="main-btn btn-hover wow fadeInUp" data-wow-delay=".45s">Try for free</a>
                                    <a href="#" className="watch-btn glightbox-howto-video wow fadeInUp" data-wow-delay=".5s"> <i
                                        className="lni lni-play"></i> <span>Watch Intro</span></a>
                                </div>

                            </div>
                            <div className="d-none counter-up">
                                <div className="single-counter wow fadeInUp" data-wow-delay=".2s">
                                    <span id="secondo" className="countup" cup-end="371" cup-append=""></span>
                                    <span>Reminders scheduled</span>
                                </div>
                                <div className="single-counter wow fadeInUp" data-wow-delay=".3s">
                                    <span id="secondo" className="countup" cup-end="4" cup-append="k"></span>
                                    <span>Reminders generated</span>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="hero-img short-intro-video wow fadeInRight" data-wow-delay=".2s">
                                <img src="/home/assets/images/reminded-main-video.png" alt="Reminded"/>
                                <a href="#" className="btn-hover video-btn glightbox-main-video"><i
                                    className="lni lni-play"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="d-none w-100 text-center wow fadeInUp">
                        <h4 className="mt-50 text-secondary" data-wow-delay=".2s">FEATURED ON</h4>
                        <img src="/home/assets/images/press-logos.png" alt=""
                             className="mx-auto" width="774"
                             data-wow-delay=".2s"/>
                    </div>
                </div>
            </section>

            <section id="how-work" className="feature-area pt-50">
                <div className="container">
                    <div className="section-title">
                        <h2 className="mb-60 wow fadeInUp" data-wow-delay=".2s">How it works</h2>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature item-1 wow fadeInUp" data-wow-delay=".2s">
                                <div className="feature-icon icon-style gradient-1">
                                    <i className="lni lni-alarm-clock "></i>
                                </div>
                                <div className="feature-content">
                                    <h4>Automate reminders</h4>
                                    <p>Schedule daily, weekly, fortnightly, monthly and annual reminders.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature item-2 wow fadeInUp" data-wow-delay=".4s">
                                <div className="feature-icon icon-style gradient-2">
                                    <i className="lni  lni-notepad"></i>
                                </div>
                                <div className="feature-content">
                                    <h4>Invite contacts</h4>
                                    <p>Invite your contacts via email or SMS.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature item-3 wow fadeInUp" data-wow-delay=".6s">
                                <div className="feature-icon icon-style gradient-3">
                                    <i className="lni lni-protection"></i>
                                </div>
                                <div className="feature-content">
                                    <h4>Interactive reminders</h4>
                                    <p>Your contacts receive and complete scheduled reminders via emai or SMS.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="single-feature item-4 wow fadeInUp" data-wow-delay=".8s">
                                <div className="feature-icon icon-style gradient-4">
                                    <i className="lni lni-cup"></i>
                                </div>
                                <div className="feature-content">
                                    <h4>Track progress</h4>
                                    <p>Easily track the status and progress of reminders via dashboards.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="feature" className="how-work-area pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="how-work-img text-center text-lg-left">
                                <img src="/home/assets/images/09.png" alt=""
                                     className="w-100 wow fadeInLeft"
                                     data-wow-delay=".2s"/>
                                {/*<img src="/home/assets/images/dots-shape.svg" alt=""
                                     className="shape dots-shape wow fadeInUp" data-wow-delay=".3s"/>*/}
                            </div>
                        </div>
                        <div className="col-xl-6 offset-xl-1 col-lg-6">
                            <div className="how-work-content-wrapper">
                                <div className="section-title">
                                    <h2 className="mb-45 wow fadeInUp" data-wow-delay=".2s">Why Reminded?</h2>
                                    <p className="mb-35 wow fadeInUp" data-wow-delay=".3s">Set routines and regular schedules for your family, friends, office team and clients.</p>
                                    <p className="mb-35 wow fadeInUp" data-wow-delay=".3s">Keep them accountable by tracking status and progress.</p>
                                </div>
                                <div className="how-work-accordion accordion-style">
                                    <div className="accordion" id="accordionExample">
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".2s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseOne" aria-expanded="true"
                                                        aria-controls="collapseOne">
											<span className="d-inline-flex mb-0 icon-style gradient-1">
												<i className="lni lni-alarm-clock"></i>
											</span>
                                                    <span>Automated</span>
                                                </button>
                                            </div>

                                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne"
                                                 data-parent="#accordionExample">
                                                <div className="accordion-content">
                                                    Setup your reminders once and they are automatically sent to your contacts on a regular schedule.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseTwo2" aria-expanded="true"
                                                        aria-controls="collapseTwo2">
											<span className="d-inline-flex mb-0 icon-style gradient-3">
												<i className="lni lni-diamond"></i>
											</span>
                                                    <span>Interactive</span>
                                                </button>
                                            </div>

                                            <div id="collapseTwo2" className="collapse" aria-labelledby="headingTwo"
                                                 data-parent="#accordionExample">
                                                <div className="accordion-content">
                                                    Reminded allows your contacts to respond to each reminder with a completion status allowing you to track progress.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".4s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseTwo" aria-expanded="true"
                                                        aria-controls="collapseTwo">
											<span className="d-inline-flex mb-0 icon-style gradient-2">
												<i className="lni lni-users"></i>
											</span>
                                                    <span>Multi-channel</span>
                                                </button>
                                            </div>

                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                                 data-parent="#accordionExample">
                                                <div className="accordion-content">
                                                    Contacts can receive reminders in-app, on email or via SMS. Your team members <b>do not</b> have to be registered on
                                                    Reminded to receive or complete reminders via email or SMS.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".5s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseThree" aria-expanded="true"
                                                        aria-controls="collapseThree">
											<span className="d-inline-flex mb-0 icon-style gradient-4">
												<i className="lni lni-control-panel"></i>
											</span>
                                                    <span>Dashboards</span>
                                                </button>
                                            </div>

                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                                 data-parent="#accordionExample">
                                                <div className="accordion-content">
                                                    Dashboards enable you to keep track of tasks and reminders with stats, charts and analysis.
                                                    Quickly see upcoming reminders, at-risk activities or late completions.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="screenshots" className="screenshot-area-wrapper pt-50">
                <div className="screenshot-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-10 mx-auto">
                                <div className="section-title text-center">
                                    <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Solutions</h2>
                                    <p className="mb-35 wow fadeInUp" data-wow-delay=".4s">Reminded is built for groups and teams.</p>
                                </div>
                            </div>
                        </div>
                        <div className="screenshot-slider-wrapper swiper-container">
                            <div className="screenshot-slider swiper-wrapper text-center">
                                <div className="single-screen swiper-slide">
                                    <h4>Home Reminders</h4>
                                    <img src="/home/assets/images/undraw_home_settings_re_pkya.svg" alt=""/>
                                    <p className="px-4 mt-4 text-sm">Bills, car repairs, contract renewals, cleaning and more.</p>
                                </div>
                                <div className="single-screen swiper-slide">
                                    <h4>Family and Friends</h4>
                                    <img src="/home/assets/images/undraw_work_chat_re_qes4.svg" alt=""/>
                                    <p className="px-4 mt-4 text-sm">Group savings, meetups, medical checkups and more.</p>
                                </div>
                                <div className="single-screen swiper-slide">
                                    <h4>Office Routines</h4>
                                    <img src="/home/assets/images/undraw_in_the_office_re_jtgc.svg" alt=""/>
                                    <p className="px-4 mt-4 text-sm">Contract renewals, cleaning, maintenance, marketing and more.</p>
                                </div>
                                <div className="single-screen swiper-slide">
                                    <h4>Client Reminders</h4>
                                    <img src="/home/assets/images/undraw_social_friends_re_7uaa.svg" alt=""/>
                                    <p className="px-4 mt-4 text-sm">Invoice payments, client check-ins, activity reminders and more.</p>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video-area pt-80">
                <div className="container">
                    <div className="video-header bg_cover">
                        <div className="section-title text-center">
                            <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Watch How It Works</h2>
                            <p className="mb-35 wow fadeInUp" data-wow-delay=".4s">Easy and simple to use.</p>
                        </div>
                    </div>
                    <div className="video-frame wow fadeInUp mt-35" data-wow-delay=".4s">
                        <img src="/home/assets/images/reminded-how-video.png" alt="Reminded" />
                            <a href="#" className="btn-hover video-btn glightbox-howto-video"><i className="lni lni-play"></i></a>
                    </div>
                </div>
            </section>

            <section id="compare" className="compare-area pt-80 mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-10 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Designed for<br/>Interactive group reminders</h2>
                                <p className="mb-35 wow fadeInUp" data-wow-delay=".4s">How Reminded compares to other apps.</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive col-xl-9 col-lg-9 col-md-10 mx-auto">
                        <table className="table table-striped fa-check text-successtable-border border-light">
                            <thead className="border-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"><strong>Reminded</strong></th>
                                <th scope="col"><strong>To-do apps</strong></th>
                                <th scope="col"><strong>Project apps</strong></th>
                                <th scope="col"><strong>Calendars</strong></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Designed for</th>
                                <td><span className="small">Interactive group reminders</span></td>
                                <td><span className="small">Daily task management</span></td>
                                <td><span className="small">Project management</span></td>
                                <td><span className="small">Scheduling events</span></td>
                            </tr>
                            <tr>
                                <th scope="row">Regular reminders</th>
                                <td><span className="text-success font-weight-bold"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                            </tr>
                            <tr>
                                <th scope="row">Multi-channel reminders</th>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                            </tr>
                            <tr>
                                <th scope="row">Interactive reminders</th>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                            </tr>
                            <tr>
                                <th scope="row">Status and analysis dashboards</th>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                                <td><span className="text-success"><i className="lni lni-checkmark"></i></span></td>
                                <td><span className="text-danger"><i className="lni lni-close"></i></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section id="testimonials" className="testimonial-area pt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="section-title">
                                <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Testimonials</h2>
                                <p className="mb-15 wow fadeInUp" data-wow-delay=".4s">What some of our happy users have to
                                    say...</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-sm-11 col-md-9 mx-auto">
                            <div className="testimonial-slider-wrapper">
                                <div className="testimonial-slider">

                                    <div className="single-testimonial wow fadeInUp" data-wow-delay=".2s">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" width="672"
                                             height="480.754" viewBox="0 0 672 480.754">
                                            <defs>
                                                <filter id="Path_2021" x="0" y="0" width="672" height="480.754"
                                                        filterUnits="userSpaceOnUse">
                                                    <feOffset input="SourceAlpha"/>
                                                    <feGaussianBlur stdDeviation="25.5" result="blur"/>
                                                    <feFlood floodColor="#a5a5a5" floodOpacity="0.161"/>
                                                    <feComposite operator="in" in2="blur"/>
                                                    <feComposite in="SourceGraphic"/>
                                                </filter>
                                            </defs>
                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2021)">
                                                <path id="Path_2021-2" data-name="Path 2021"
                                                      d="M36-50,483,1.212c19.882,0,36,16.508,36,36.872v202.8c0,20.364-16.118,36.872-36,36.872H36c-19.882,0-36-16.508-36-36.872V-13.128C0-33.492,16.118-50,36-50Z"
                                                      transform="translate(76.5 126.5)" fill="#fff"/>
                                            </g>
                                        </svg>
                                        <div className="testimonial-header">
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <i className="f-42 lni lni-bi-cycle"></i>
                                                </div>
                                                <div className="client-details">
                                                    <h6>Fiona, UK</h6>
                                                    <span>Freelancer</span>
                                                </div>
                                            </div>
                                            <div className="client-rating">
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                            </div>
                                        </div>
                                        <div className="quote">
                                            <i className="lni lni-quotation gradient-1"></i>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>I use Reminded at home to set reminders for annual contract renewals such as insurance and other service providers.
                                                I aso use it for and with my clients. It is a great tool!</p>
                                        </div>
                                    </div>

                                    <div className="single-testimonial wow fadeInUp" data-wow-delay=".2s">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" width="672"
                                             height="480.754" viewBox="0 0 672 480.754">
                                            <defs>
                                                <filter id="Path_2021" x="0" y="0" width="672" height="480.754"
                                                        filterUnits="userSpaceOnUse">
                                                    <feOffset input="SourceAlpha"/>
                                                    <feGaussianBlur stdDeviation="25.5" result="blur"/>
                                                    <feFlood floodColor="#a5a5a5" floodOpacity="0.161"/>
                                                    <feComposite operator="in" in2="blur"/>
                                                    <feComposite in="SourceGraphic"/>
                                                </filter>
                                            </defs>
                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2021)">
                                                <path id="Path_2021-2" data-name="Path 2021"
                                                      d="M36-50,483,1.212c19.882,0,36,16.508,36,36.872v202.8c0,20.364-16.118,36.872-36,36.872H36c-19.882,0-36-16.508-36-36.872V-13.128C0-33.492,16.118-50,36-50Z"
                                                      transform="translate(76.5 126.5)" fill="#fff"/>
                                            </g>
                                        </svg>
                                        <div className="testimonial-header">
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <i className="f-42 lni lni-user"></i>
                                                </div>
                                                <div className="client-details">
                                                    <h6>Paul M, UK</h6>
                                                    <span>Estate Agent</span>
                                                </div>
                                            </div>
                                            <div className="client-rating">
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                            </div>
                                        </div>
                                        <div className="quote">
                                            <i className="lni lni-quotation gradient-2"></i>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>We manage many properties. Reminded has drastically improved how we set and track
                                                reminders to tenants for rent payments or contract renewals.</p>
                                        </div>
                                    </div>

                                    <div className="single-testimonial wow fadeInUp" data-wow-delay=".4s">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" width="672"
                                             height="480.754" viewBox="0 0 672 480.754">
                                            <defs>
                                                <filter id="Path_2021" x="0" y="0" width="672" height="480.754"
                                                        filterUnits="userSpaceOnUse">
                                                    <feOffset input="SourceAlpha"/>
                                                    <feGaussianBlur stdDeviation="25.5" result="blur"/>
                                                    <feFlood floodColor="#a5a5a5" floodOpacity="0.161"/>
                                                    <feComposite operator="in" in2="blur"/>
                                                    <feComposite in="SourceGraphic"/>
                                                </filter>
                                            </defs>
                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2021)">
                                                <path id="Path_2021-2" data-name="Path 2021"
                                                      d="M36-50,483,1.212c19.882,0,36,16.508,36,36.872v202.8c0,20.364-16.118,36.872-36,36.872H36c-19.882,0-36-16.508-36-36.872V-13.128C0-33.492,16.118-50,36-50Z"
                                                      transform="translate(76.5 126.5)" fill="#fff"/>
                                            </g>
                                        </svg>
                                        <div className="testimonial-header">
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <i className="f-42 lni lni-users"></i>
                                                </div>
                                                <div className="client-details">
                                                    <h6>Gemma, UAE</h6>
                                                    <span>Sustainability Consultant</span>
                                                </div>
                                            </div>
                                            <div className="client-rating">
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                            </div>
                                        </div>
                                        <div className="quote">
                                            <i className="lni lni-quotation gradient-3"></i>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>Reminded is fantastic. It helps me track regular client activities like contract renewals, payments and
                                                submission of performance metrics.</p>
                                        </div>
                                    </div>

                                    <div className="single-testimonial wow fadeInUp" data-wow-delay=".6s">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" width="672"
                                             height="480.754" viewBox="0 0 672 480.754">
                                            <defs>
                                                <filter id="Path_2021" x="0" y="0" width="672" height="480.754"
                                                        filterUnits="userSpaceOnUse">
                                                    <feOffset input="SourceAlpha"/>
                                                    <feGaussianBlur stdDeviation="25.5" result="blur"/>
                                                    <feFlood floodColor="#a5a5a5" floodOpacity="0.161"/>
                                                    <feComposite operator="in" in2="blur"/>
                                                    <feComposite in="SourceGraphic"/>
                                                </filter>
                                            </defs>
                                            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_2021)">
                                                <path id="Path_2021-2" data-name="Path 2021"
                                                      d="M36-50,483,1.212c19.882,0,36,16.508,36,36.872v202.8c0,20.364-16.118,36.872-36,36.872H36c-19.882,0-36-16.508-36-36.872V-13.128C0-33.492,16.118-50,36-50Z"
                                                      transform="translate(76.5 126.5)" fill="#fff"/>
                                            </g>
                                        </svg>
                                        <div className="testimonial-header">
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <i className="f-42 lni lni-users"></i>
                                                </div>
                                                <div className="client-details">
                                                    <h6>Fred, UK</h6>
                                                    <span>Building Contractor</span>
                                                </div>
                                            </div>
                                            <div className="client-rating">
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                                <span><i className="lni lni-star-filled"></i></span>
                                            </div>
                                        </div>
                                        <div className="quote">
                                            <i className="lni lni-quotation gradient-4"></i>
                                        </div>
                                        <div className="testimonial-content">
                                            <p>Using Reminded is simple and straightforward. I use it for reminders to my team and sub-contractors
                                                for building inspections, repairs, payments and contracts.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pricing" className=" pricing-area pt-50 ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-10 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Pricing</h2>
                                <p className="mb-50 wow fadeInUp" data-wow-delay=".4s">Pay-As-You-Go pricing. Cancel
                                    anytime.<br/>15% discount for annual payments.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mx-auto">
                            <div className="pricing-nav">
                                <ul className="nav nav-pills justify-content-center align-items-center mb-50 wow fadeInUp"
                                    data-wow-delay=".2s" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="pills-month-tab" data-toggle="pill"
                                           href="#pills-month" role="tab"
                                           aria-controls="pills-month" aria-selected="true">Monthly</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="pills-year-tab" data-toggle="pill"
                                           href="#pills-year" role="tab"
                                           aria-controls="pills-year" aria-selected="false">Yearly</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade price-tab show active" id="pills-month"
                                         role="tabpanel" aria-labelledby="pills-month-tab">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center wow fadeInUp"
                                                     data-wow-delay=".2s">
                                                    <div className="price-icon icon-style mx-auto gradient-1">
                                                        <i className="lni lni-user"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Personal</h4>
                                                    <h4 className="package-price mb-20">Free</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 10 ongoing activities</li>
                                                        <li>Up to 5 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Analysis</li>
                                                        <li>Email support</li>
                                                        <li className="line-through">Priority support</li>
                                                        <li className="line-through">Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center active wow fadeInUp"
                                                     data-wow-delay=".4s">
                                                    <div className="price-icon icon-style mx-auto gradient-2">
                                                        <i className="lni lni-users"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Pro</h4>
                                                    <h4 className="package-price mb-20">£ 4.99</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 40 ongoing activities</li>
                                                        <li>Up to 25 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Analysis</li>
                                                        <li>24/7 email support</li>
                                                        <li className="line-through">Priority support</li>
                                                        <li className="line-through">Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center wow fadeInUp"
                                                     data-wow-delay=".6s">
                                                    <div className="price-icon icon-style mx-auto gradient-3">
                                                        <i className="lni lni-diamond"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Business</h4>
                                                    <h4 className="package-price mb-20">£ 9.99</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 95 ongoing activities</li>
                                                        <li>Up to 50 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Detailed analysis</li>
                                                        <li>24/7 email support</li>
                                                        <li>Priority support</li>
                                                        <li>Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade price-tab" id="pills-year" role="tabpanel"
                                         aria-labelledby="pills-year-tab">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center wow fadeInUp"
                                                     data-wow-delay=".2s">
                                                    <div className="price-icon icon-style mx-auto gradient-1">
                                                        <i className="lni lni-user"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Personal</h4>
                                                    <h4 className="package-price mb-20">Free</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 10 ongoing activities</li>
                                                        <li>Up to 5 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Analysis</li>
                                                        <li>Email support</li>
                                                        <li className="line-through">Priority support</li>
                                                        <li className="line-through">Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center active wow fadeInUp"
                                                     data-wow-delay=".4s">
                                                    <div className="price-icon icon-style mx-auto gradient-2">
                                                        <i className="lni lni-users"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Pro</h4>
                                                    <h4 className="package-price mb-20">£ 49.99</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 40 ongoing activities</li>
                                                        <li>Up to 25 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Analysis</li>
                                                        <li>24/7 email support</li>
                                                        <li className="line-through">Priority support</li>
                                                        <li className="line-through">Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="single-price text-center wow fadeInUp"
                                                     data-wow-delay=".6s">
                                                    <div className="price-icon icon-style mx-auto gradient-3">
                                                        <i className="lni lni-diamond"></i>
                                                    </div>
                                                    <h4 className="package-name mb-20">Business</h4>
                                                    <h4 className="package-price mb-20">£ 99.99</h4>
                                                    <ul className="package-details">
                                                        <li>Up to 95 ongoing activities</li>
                                                        <li>Up to 50 contacts per activity</li>
                                                        <li>Unlimited archived activities</li>
                                                        <li>Unlimited lists</li>
                                                        {/*<li>Email & SMS reminders</li>
                                                        <li>Awards and badges</li>
                                                        <li>Detailed analysis</li>
                                                        <li>24/7 email support</li>
                                                        <li>Priority support</li>
                                                        <li>Detailed historical logs</li>*/}
                                                    </ul>
                                                    <a href="/register" className="btn-hover price-btn main-btn">Start</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 text-center wow fadeInUp">
                        <h4 className="mt-50 text-secondary" data-wow-delay=".2s">POWERED BY</h4>
                        <img src="/home/assets/images/tech-logos.png" alt=""
                             className="mx-auto" width="774"
                             data-wow-delay=".2s"/>
                    </div>
                </div>
            </section>

            <section id="faqs" className="contact-area pt-100 ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-10 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="mb-60 wow fadeInUp" data-wow-delay=".2s">Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6" style={{margin: "0 auto"}}>
                            <div className="faq-wrapper">
                                <div className="faq-accordion accordion-style">
                                    <div className="accordion" id="accordionExample2">
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapse1" aria-expanded="true"
                                                        aria-controls="collapse1">
                                                    <span>Why should I use Reminded?</span>
                                                </button>
                                            </div>

                                            <div id="collapse1" className="collapse" aria-labelledby="heading8"
                                                 data-parent="#accordionExample2">
                                                <div className="accordion-content">
                                                    Managing group reminders is a challenge we all face.
                                                    <br/><br/>There are many tools for personal reminders but nothing specifically for
                                                    group reminders. Calendar apps, task management and project management apps help but they are not quite there.
                                                    <br/><br/>Reminded is specifically designed to allow you to schedule and track group reminders for family,
                                                    friends, your team and clients.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".4s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapse8" aria-expanded="true"
                                                        aria-controls="collapse8">
                                                    <span>How easy is it to get started?</span>
                                                </button>
                                            </div>

                                            <div id="collapse8" className="collapse" aria-labelledby="heading8"
                                                 data-parent="#accordionExample2">
                                                <div className="accordion-content">
                                                    You can be up and running within 5 minutes!
                                                    <br/><br/>
                                                    Signup is easy and straightforward - use your email address and set a password. Once registered create an activity,
                                                    add contacts and set a schedule for reminders. You can add a custom message too if you wish.
                                                    <br/><br/>
                                                    That's it! Manage and track your important reminders via ready-made dashboards and activity pages.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".5s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapse9" aria-expanded="true"
                                                        aria-controls="collapse9">
                                                    <span>Do my contacts also need to be registered?</span>
                                                </button>
                                            </div>

                                            <div id="collapse9" className="collapse" aria-labelledby="heading8"
                                                 data-parent="#accordionExample2">
                                                <div className="accordion-content">
                                                    No. Your contacts do no have to be registered to receive and respond to reminders.
                                                    <br/><br/>
                                                    They will receive invitations and reminders via
                                                    email or SMS and are able to respond via a simple
                                                    secure link.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".6s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapse5" aria-expanded="true"
                                                        aria-controls="collapse5">
                                                    <span>Can I change or cancel my subscription?</span>
                                                </button>
                                            </div>

                                            <div id="collapse5" className="collapse" aria-labelledby="heading5"
                                                 data-parent="#accordionExample2">
                                                <div className="accordion-content">
                                                    Yes. You can change or cancel at anytime. Old and/or new charges
                                                    will be
                                                    prorated based on your usage to date.
                                                    <br/><br/>
                                                    To move to a lower subscription you will need to reduce your active
                                                    activities and contacts in each activity to the lower limits.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-accordion mb-30 wow fadeInUp" data-wow-delay=".7s">
                                            <div className="accordion-btn">
                                                <button className="btn-block text-left collapsed" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapse6" aria-expanded="true"
                                                        aria-controls="collapse6">
                                                    <span>Are my payment details safe?</span>
                                                </button>
                                            </div>

                                            <div id="collapse6" className="collapse" aria-labelledby="heading6"
                                                 data-parent="#accordionExample2">
                                                <div className="accordion-content">
                                                    Yes they are. We use Stripe to process card payments via encrypted
                                                    channels.
                                                    Stripe is a fully regulated, PCI compliant, international payments
                                                    processor.
                                                    <br/><br/>
                                                    We do not handle or process your payment details directly.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*<section className="download-area pt-150">
                <div className="container">
                    <div className="download-wrapper bg_cover">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 offset-1 col-11">
                                <div className="download-content">
                                    <div className="section-title">
                                        <h2 className="mb-30 text-white wow fadeInUp" data-wow-delay=".2s">Download the
                                            app now</h2>
                                        <p className="mb-40 text-white wow fadeInUp" data-wow-delay=".35s">Lorem ipsum
                                            dolor sit amet, consetetur sadipscing elitr, sed diamnonumy eirmod tempor
                                            invidunt labore.</p>
                                    </div>
                                    <div className="download-btns">
                                        <a href="#" className="btn-hover download-btn mr-4 wow fadeInUp"
                                           data-wow-delay=".45s">
                                            <span className="icon gradient-2"><i className="lni lni-apple"></i></span>
                                            <span className="text">App Store <b>Download now</b></span>
                                        </a>
                                        <a href="#" className="btn-hover download-btn wow fadeInUp"
                                           data-wow-delay=".55s">
                                            <span className="icon gradient-1"><i
                                                className="lni lni-play-store"></i></span>
                                            <span className="text">Play Store <b>Download now</b></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 align-self-end col-lg-5 col-11 offset-1 offset-lg-0">
                                <div className="download-img wow fadeInRight" data-wow-delay=".2s">
                                    <img src="/home/assets/images/cta-right-img.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            <footer id="footer" className="footer-area bg_cover">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".2s">
                                <a href="/" className="mb-15 d-block">
                                    <img className="logo" src="/assets/Reminded-logo.png" alt="Reminded"/> </a>
                                <p className="mb-35">Use Reminded to schedule and track automated reminders for your friends,
                                    your team and your clients via email or SMS.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 offset-xl-1 col-lg-2 col-md-6">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".4s">
                                <h4>About</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/#how-work">How it Works</a></li>
                                    <li><a href="/#feature">Features</a></li>
                                    <li><a href="/#screenshots">Solutions</a></li>
                                    <li><a href="/#testimonials">Testimonials</a></li>
                                    <li><a href="/#pricing">Pricing</a></li>
                                    <li><a href="/#faqs">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-2 col-md-6">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".6s">
                                <h4>Legal</h4>
                                <ul>
                                    <li><a href="/privacy" target="_blank">Privacy policy</a></li>
                                    <li><a href="/terms" target="_blank">Terms & Conditions</a></li>
                                    <li><a href="mailto:hello@reminded.io">Get in touch</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".8s">
                                <h4>Join</h4>
                                <ul>
                                    <li><a href="/login">Sign in</a></li>
                                    <li><a href="/register">Sign up</a></li>
                                    <li><a href="mailto:hello@reminded.io">Request a Demo</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="footer-cradit">
                        <p className="text-center mb-0">&copy; {new Date().getFullYear()} Reminded - Automated reminders for groups.</p>
                    </div>
                </div>
            </footer>

            <a href="#" className="back-to-top btn-hover"><i className="lni lni-chevron-up"></i></a>

            <CookieConsentComponent/>



        </div>
    );
}

