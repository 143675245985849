import React from "react";

export default function ListItem(props) {

    const showEditItemModal = () => {
        window.setTaskModalFormFields(props.data);

        document.getElementById('add-item-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit activity';
        document.getElementById('add-item-modal').style.display = "block";
    };

    const showItemModal = () => {
        window.setItemObj(props);

        document.getElementById('show-item-modal').style.display = "block";
    };

    var date_options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    var start_date = new Date(props.data.start_date).toDateString();
    var end_date = new Date(props.data.end_date).toDateString();

    var ItemStatus = 'in progress';
    if (new Date(props.data.end_date) < new Date()) {
        ItemStatus = 'completed';
    } else if (props.data.status === 'paused' || props.data.status === 'stopped') {
        ItemStatus = props.data.status;
    }


    return (

        <li className="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-3">
            <div className="checklist-item checklist-item-primary ps-2 ms-3">
                <div className="d-flex align-items-center">
                    <h6 className="mb-0 text-dark text-sm"><a href={"/activity/" + props.data.id}>{props.data.title}</a></h6>
                    <div className="dropstart  float-lg-end ms-auto d-none d-md-block">
                        <a href={"/activity/" + props.data.id} title="show"
                           className='btn btn-muted btn-round settings-btn'>
                            <i className="material-icons">launch</i> Show details
                        </a>
                        {!props.mode && <button type="button" rel="tooltip" onClick={showEditItemModal} title="Edit"
                                className='btn btn-muted btn-round settings-btn'>
                            <i className="material-icons">settings</i> Edit
                        </button>}
                    </div>
                </div>
                <div className="d-block d-md-none">
                    <a href={"/activity/" + props.data.id} title="show"
                       className='btn btn-muted btn-round settings-btn mb-0 mt-3'>
                        <i className="material-icons">launch</i> Show details
                    </a>
                    {!props.mode && <button type="button" rel="tooltip" onClick={showEditItemModal} title="Edit"
                                            className='btn btn-muted btn-round settings-btn mb-0 mt-3'>
                        <i className="material-icons">settings</i> Edit
                    </button>}
                </div>
                <div className="d-flex align-items-center ms-3 ms-md-4 mt-3 ps-1">
                    <div>
                        <h6 className="mb-0 text-sm">Status</h6>
                        <span className="badge badge-dot me-4">
                            {ItemStatus === 'in progress' && <i className="bg-success"></i>}
                            {ItemStatus === 'completed' && <i className="bg-secondary"></i>}
                            {ItemStatus === 'stopped' && <i className="bg-danger"></i>}
                            {ItemStatus === 'paused' && <i className="bg-warning"></i>}
                            <span className="text-dark text-sm">{ItemStatus}</span>
                        </span>
                    </div>
                    <div className="ms-auto">
                        <h6 className="mb-0 text-sm">Reminders sent</h6>
                        <span className="text-capitalize text-sm">{props.data.frequency}</span>
                    </div>
                    <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">Start</h6>
                        <span className="text-capitalize text-sm">{start_date}</span>
                    </div>
                    <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">End</h6>
                        <span className="text-capitalize text-sm">{end_date}</span>
                    </div>
                    {props.data.task_assignees && props.data.task_assignees.length > 0 && <div className="mx-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">Contacts</h6>
                        <div className="avatar-group mx-w-150px">
                            {props.data.task_assignees.map((c, index, prop) => (
                                <button key={c.id} className="avatar avatar-xs rounded-circle ms-0 tooltip"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title={c.contact}
                                        data-bs-original-title={c.contact}>
                                    <span> {c.user_assignee.contact.substring(0, 1)} </span>
                                    <span className="tooltiptext">{c.user_assignee.contact}</span>
                                </button>
                            ))}
                        </div>
                    </div>}
                    {props.mode === 'assignee' && <div className="ms-auto d-none d-md-block">
                        <h6 className="mb-0 text-sm">Invitation status</h6>
                        <span className="text-capitalize text-sm">{props.data.invitation_status}</span>
                    </div>}
                </div>
            </div>
            <hr className="horizontal dark mt-4 mb-0"/>
        </li>

    );
}