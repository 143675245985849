import React, {useState, useEffect} from "react";
import ListItem from "./ListItem";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";


export default function List(id) {

    const actveListID = id;
    const [listData, updateListData] = useState();
    const [loading, setLoading] = useState(true);

    window.actveListID = actveListID
    window.updateListData = updateListData;

    useEffect(() => {
        axios.get(APIService.API_URL + "user_tasklists/get_by_id", {
            params: {"id": id.id},
            headers: {"Authorization": APIService.getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateListData(response.data.user_tasklist);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, [id]);

    const showAddItemModal = (data) => {

        var currUser = APIService.getCurrentUser();
        var active_tasks = APIService.getActiveTasks();
        if (currUser && active_tasks) {
            var limitActivities = currUser.user.subscription_plan.activities;
            var active_num_tasks = active_tasks;

            if (active_num_tasks >= limitActivities) {
                APIService.showUpgradeAlert(active_num_tasks, limitActivities);
                return;
            }
        }

        window.setTaskModalListField({title: listData.tasklist.title});
        document.getElementById('add-item-modal').style.display = "block";
    };

    const showEditListModal = () => {
        window.setListModalFormFields({data: listData.tasklist});
        document.getElementById('add-list-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit your list';
        document.getElementById('add-list-modal').style.display = "block";
    };

    if (loading) {
        return <div/>;
    }

    return (
        <div className="row mt-lg-4 mt-2 mb-4">
            <div className="card">
                <div className="card-header p-3">
                    <div className="d-flex mt-n2">
                        <div
                            className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-' + listData.tasklist.color}>
                            <i className="material-icons opacity-10">checklist</i>
                        </div>
                        <div className="ms-3 my-auto">
                            <h4 className="mb-0">{listData.tasklist.title}</h4>
                        </div>
                        <div className="ms-auto d-none d-md-block">
                            <button className="btn btn-sm bg-gradient-primary m-3 mr-1"
                                    onClick={showAddItemModal}>+&nbsp; Add activity
                            </button>
                            <button className="btn btn-sm bg-gradient-dark m-3" onClick={showEditListModal}>Edit
                            </button>
                        </div>
                    </div>
                    <p className="mt-2">{listData.tasklist.description}</p>
                    <hr className="horizontal dark"/>
                </div>

                <div className="card-body">
                    {listData.tasks.length > 0 && <ul className="list-group list-group-flush">

                        {listData.tasks.map((c, index) => (
                            <ListItem
                                data={c}
                                key={index}
                                index={index}
                            />
                        ))}

                    </ul>}
                    {listData.tasks.length < 1 &&
                    <p>You have no activities in this list.</p>
                    }

                </div>
            </div>
        </div>
    );
};

