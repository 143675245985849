import React from "react";

export default function TermsAndConditions() {

    return (
        <div>
            <div className="card mt-3 mb-5 p-1">
                <div className="row mt-5 mb-5">
                    <div className="col-md-7 mx-auto terms-list">

                        <h3 className="privacy-policy-h1">Terms & Conditions</h3>
                        <p>Last Updated On 30-Sep-2021<br/>
                            Effective Date 30-Sep-2021
                        </p>


                        <ol className="main-list">
                            <li className="main-list">
                                <h4 className="text-capitalize">Application of Terms</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">These Terms apply to your use of the Service (as that term is defined
                                            below). By setting up an account, accessing and using the Service:
                                            <ol type="a">
                                                <li>you agree to these Terms; and</li>
                                                <li>where your access and use is on behalf of another person (e.g. a
                                                    company), you confirm that you are authorised to, and do in fact, agree to these Terms on
                                                    that person’s behalf and that, by agreeing to these Terms on that person’s behalf, that
                                                    person is bound by these Terms.
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list"> If you do not agree to these Terms, you are not authorised to access and
                                            use the Service, and you must immediately stop doing so.
                                        </li>
                                    </ol>
                                </p>
                            </li>


                            <li className="main-list">
                                <h4>Changes</h4>

                                <p>

                                    We may change these Terms at any time by notifying you of the change by
                                    email or by posting a notice on the Website. Unless stated otherwise, any change takes effect
                                    from the date set out in the notice. You are responsible for ensuring you are familiar with
                                    the latest Terms. By continuing to access and use the Service from the date on which the Terms
                                    are changed, you agree to be bound by the changed Terms.
                                </p>

                            </li>

                            <li className="main-list">
                                <h4>Interpretation</h4>
                                <p>In these Terms:</p>
                                <p>Reminded Software means the software owned by us (and our licensors) that is
                                    used to provide the Service.</p>

                                <p><b>Confidential Information</b> means any information that is not public knowledge
                                    and that is
                                    obtained from the other party in the course of, or in connection with, the provision
                                    and use of the Service. Our Confidential Information includes Intellectual Property owned by us
                                    (or our licensors), including the Reminded Software. Your Confidential Information
                                    includes the Data.</p>
                                <p><b>Data</b> means all data, content, and information (including personal information)
                                    owned, held, used or created by you or on your behalf that is stored using, or inputted into, the
                                    Service.</p>

                                <p><b>Fees</b> means the applicable fees set out on our pricing page on the Website
                                    or as agreed otherwise in writing between you and us, as may be updated from time to time
                                    in accordance with clause 7.6. The Fees are in the currencies stated on the Website and are non-refundable.</p>

                                <p><b>Subscription</b> means automatically recurring payments for periodic charges.</p>

                                <p><b>Force Majeure</b> means an event that is beyond the reasonable control of a party,
                                    excluding:
                                    <ul>
                                        <li>an event to the extent that it could have been avoided by a party taking
                                            reasonable steps or reasonable care; or
                                        </li>
                                        <li>a lack of funds for any reason.</li>
                                    </ul>
                                </p>
                                <p>including and similar words do not imply any limit.</p>
                                <p><b>Intellectual Property Rights</b> includes copyright and all rights existing
                                    anywhere in the world conferred under statute, common law or equity relating to inventions (including
                                    patents), registered and unregistered trade marks and designs, circuit layouts, data and
                                    databases,
                                    confidential information, know-how, and all other rights resulting from intellectual
                                    activity.
                                    Intellectual Property has a consistent meaning, and includes any enhancement,
                                    modification or
                                    derivative work of the Intellectual Property.</p>
                                <p><b>Objectionable</b> includes being objectionable, defamatory, obscene, harassing,
                                    threatening,
                                    harmful, or unlawful in any way.</p>
                                <p>a <b>party</b> includes that party’s permitted assigns.</p>


                                <p> a <b>person</b> includes an individual, a body corporate, an association of persons
                                    (whether
                                    corporate or not), a trust, a government department, or any other entity.</p>
                                <p><b>personal information</b> means information about an identifiable, living person.</p>
                                <p><b>personnel</b> includes officers, employees, contractors and agents, but a reference to
                                    your
                                    personnel does not include us.</p>
                                <p><b>Service</b> means the service having the core functionality described on the Website, as
                                    the
                                    Website is updated from time to time.</p>
                                <p><b>Start Date</b> means the date that you set up an account.</p>
                                <p><b>Terms</b> means these terms titled SaaS terms of use.</p>
                                <p><b>Underlying Systems</b> means the Reminded Software, IT solutions, systems and
                                    networks
                                    (including software and hardware) used to provide the Service, including any third
                                    party
                                    solutions, systems and networks.</p>
                                <p><b>We, us or our means</b> ZURI CONSULTING LIMITED (trading as Reminded),
                                    company number 12867637.</p>
                                <p><b>Website</b> means the internet site at Reminded.io, or such other site notified to you
                                    by us.</p>
                                <p><b>Year</b> means a 12-month period starting on the Start Date or the anniversary of that
                                    date.</p>

                                <p><b>You or your</b> means you or, if clause 1.1b applies, both you and the other person on
                                    whose
                                    behalf you are acting.</p>
                                <p>Words in the singular include the plural and vice versa.</p>
                                <p>A reference to a statute includes references to regulations, orders or notices made
                                    under or in
                                    connection with the statute or regulations and all amendments, replacements or other
                                    changes
                                    to any of them.</p></li>


                            <li className="main-list">
                                <h4>Provision of Service</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">We must use reasonable efforts to provide the Service:
                                            <ol type="a">
                                                <li>in accordance with these Terms and the law of England and Wales;</li>
                                                <li>exercising reasonable care, skill and diligence; and</li>
                                                <li>using suitably skilled, experienced and qualified personnel.</li>
                                            </ol></li>
                                        <li className="sub-list">Our provision of the Service to you is non-exclusive. Nothing in these Terms
                                            prevents us from
                                            providing the Service to any other person.
                                        </li>
                                        <li className="sub-list">Subject to clause 4.4, we must use reasonable efforts to ensure the
                                            Service
                                            is available. However, it
                                            is
                                            possible that on
                                            occasion the Service may be unavailable to permit maintenance or other
                                            development activity
                                            to take place, or in the event of Force Majeure. We must use reasonable
                                            efforts
                                            to publish on
                                            the Website and/or notify you by email advance details of any
                                            unavailability.

                                        </li>
                                        <li className="sub-list">Through the use of web services and APIs, the Service interoperates with a
                                            range of third
                                            party service features. We do not make any warranty or representation on the
                                            availability of
                                            those features. Without limiting the previous sentence, if a third party
                                            feature
                                            provider ceases
                                            to provide that feature or ceases to make that feature available on
                                            reasonable
                                            terms, we may
                                            cease to make available that feature to you. To avoid doubt, if we exercise
                                            our
                                            right to cease
                                            the availability of a third party feature, you are not entitled to any
                                            refund,
                                            discount or other
                                            compensation.
                                        </li>
                                    </ol>
                                </p>
                            </li>

                            <li className="main-list">
                                <h4>Your Obligations</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">You and your personnel must:
                                            <ol type="a">
                                                <li>use the Service in accordance with these Terms solely for:
                                                    <ol type="i">
                                                        <li>your own personal or internal business purposes to create, manage or monitor
                                                            tasks and activities for appropriate and ethical reasons; and
                                                        </li>
                                                        <li>lawful purposes including complying with the appropriate laws; and
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>not resell or make available the Service to any third party, or
                                                    otherwise commercially
                                                    exploit the Service.
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">When accessing the Service, you and your personnel must:
                                            <ol type="a">
                                                <li>not impersonate another person or misrepresent authorisation to act
                                                    on
                                                    behalf of others
                                                    or us;</li>
                                                    <li>correctly identify the sender of all electronic
                                                        transmissions;</li>
                                                    <li>not attempt to undermine the security or integrity of the
                                                        Underlying
                                                        Systems;
                                                    </li>
                                                    <li>not use, or misuse, the Service in any way which may impair the
                                                        functionality of the
                                                        Underlying Systems or impair the ability of any other user to
                                                        use
                                                        the Service;
                                                    </li>
                                                    <li>not attempt to view, access or copy any material or data other
                                                        than:
                                                        <ol type="i">
                                                            <li>that which you are authorised to access; and</li>
                                                            <li>to the extent necessary for you to use the Service in
                                                                accordance with these Terms; and
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li>neither use the Service in a manner, nor transmit, input or
                                                        store
                                                        any Data, that breaches
                                                        any third party right (including Intellectual Property Rights
                                                        and
                                                        privacy rights) or is
                                                        Objectionable, incorrect or misleading.
                                                    </li>
                                            </ol></li>
                                        <li className="sub-list">A breach of any of these Terms by your personnel is deemed to be a breach of these Terms by you.
                                        </li>
                                        <li className="sub-list">You are responsible for procuring all licences, authorisations and consents
                                            required for you and
                                            your personnel to use the Service, including to use, store and input Data
                                            into,
                                            and process and
                                            distribute Data through, the Service.
                                        </li>
                                        <li className="sub-list">
                                            You must be at least 13 years old to use the Service. By using the Service,
                                            you represent and warrant that you are at least 13 years old, are eligible to use the Service and
                                            will use the Service in compliance with any and all applicable laws and regulations.
                                        </li>
                                    </ol>
                                </p>
                            </li>


                            <li className="main-list">
                                <h4>Data</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">You acknowledge that:
                                            <ol type="a">
                                                <li>we may require access to the Data to exercise our rights and perform our
                                            obligations
                                            under these Terms; and</li>
                                                <li>to the extent that this is necessary but subject to clause 9, we may
                                            authorise
                                            a member
                                            or members of our personnel to access the Data for this purpose.</li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">You must arrange all consents and approvals that are necessary for us to
                                            access the Data as
                                            described in clause 6.1.
                                        </li>
                                        <li className="sub-list">You acknowledge and agree that:
                                            <ol type="a">
                                                <li>we may:
                                                    <ol type="i">
                                                        <li>use Data and information about your and your end users’
                                                            use of the Service to
                                                            generate anonymised and aggregated statistical and
                                                            analytical data (Analytical
                                                            Data); and
                                                        </li>
                                                        <li>use Analytical Data for our internal research and product
                                                            development purposes
                                                            and to conduct statistical analysis and identify trends and
                                                            insights; and
                                                        </li>
                                                        <li>supply Analytical Data to third parties;</li>
                                                    </ol>
                                                </li>
                                                <li>our rights under clause 6.3a above will survive termination or
                                                    expiry of these Terms; and
                                                </li>
                                                <li>title to, and all Intellectual Property Rights in, Analytical Data
                                                    is and remains our property.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="sub-list">You acknowledge and agree that to the extent Data contains personal
                                            information, in collecting,
                                            holding and processing that information through the Service, we are acting
                                            as your agent for
                                            the purposes of any applicable privacy law.
                                            You must obtain all
                                            necessary consents from the relevant individual to enable us to collect,
                                            use, hold and process
                                            that information in accordance with these Terms.
                                        </li>
                                        <li className="sub-list">While we will take standard industry measures to back up all Data stored
                                            using the Service, you
                                            agree to keep a separate back-up copy of all Data uploaded by you onto the
                                            Service.
                                        </li>
                                        <li className="sub-list">You agree that we may store Data (including any personal information) in
                                            secure servers in the United Kingdom and
                                            overseas territories and may access that Data (including any
                                            personal information) in the United Kingdom and
                                            overseas territories.
                                        </li>
                                        <li className="sub-list">You indemnify us against any liability, claim, proceeding, cost, expense
                                            (including the actual
                                            legal fees charged by our solicitors) and loss of any kind arising from any
                                            actual or alleged
                                            claim by a third party that any Data infringes the rights of that third
                                            party (including Intellectual
                                            Property Rights and privacy rights) or that the Data is Objectionable,
                                            incorrect or misleading.
                                        </li>
                                    </ol>
                                </p>
                            </li>


                            <li className="main-list">
                                <h4>Fees</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">You must pay us the Fees.</li>
                                        <li className="sub-list">You will be billed in advance on a recurring and periodic basis. You authorize Us or our
                                            partner payment processors to periodically charge the Fees, on a going-forward
                                            basis and until cancellation of either the recurring payments or your account.
                                        </li>
                                        <li className="sub-list">Your Subscription will automatically renew and your payment method for such Subscription will automatically
                                            be charged the Fees applicable to your Subscription unless you cancel your Subscription prior to your renewal date.</li>

                                        <li className="sub-list">We reserve the right to suspend or terminate access to the Service if the Fees are due but unpaid.
                                            In addition to the Fees, a delinquent account may be charged interest or administrative charges arising from collection
                                            of the Fees.
                                        </li>
                                        <li className="sub-list"> We reserve the right to change the pricing for the Service.
                                            Any changes to the Fees will be published on the Website
                                            pricing page. If you do not wish to pay the increased Fees, you may terminate
                                            these Terms and your right to access and use the Service. If you do not terminate
                                            these Terms and your right to access and use the Service in accordance with this
                                            clause, you are deemed to have accepted the increased Fees.
                                        </li>
                                    </ol>
                                </p>
                            </li>


                            <li className="main-list">
                                <h4>Intellectual Property</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Subject to clause 8.2, title to, and all Intellectual Property Rights in,
                                            the
                                            Service,
                                            the Website,
                                            and all Underlying Systems is and remains our property (and our licensors’
                                            property). You
                                            must not contest or dispute that ownership, or the validity of those
                                            Intellectual Property
                                            Rights.
                                        </li>
                                        <li className="sub-list">Title to, and all Intellectual Property Rights in, the Data (as between the
                                            parties)
                                            remains your
                                            property. You grant us a worldwide, non-exclusive, fully paid up,
                                            transferable,
                                            irrevocable
                                            licence to use, store, copy, modify, make available and communicate the Data
                                            for
                                            any purpose
                                            in connection with the exercise of our rights and performance of our
                                            obligations
                                            in
                                            accordance
                                            with these Terms.
                                        </li>
                                        <li className="sub-list">To the extent not owned by us, you grant us a royalty-free, transferable,
                                            irrevocable
                                            and
                                            perpetual licence to use for our own business purposes any know-how,
                                            techniques,
                                            ideas,
                                            methodologies, and similar Intellectual Property used by us in the provision
                                            of
                                            the Service.
                                        </li>
                                        <li className="sub-list">If you provide us with ideas, comments or suggestions relating to the
                                            Service or
                                            Underlying
                                            Systems (together feedback):
                                            <ol type="a">
                                                <li>all Intellectual Property Rights in that feedback, and anything
                                                    created
                                                    as a result of
                                                    that
                                                    feedback (including new material, enhancements, modifications or
                                                    derivative works), are
                                                    owned solely by us; and
                                                </li>
                                                <li>we may use or disclose the feedback for any purpose.</li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">You acknowledge that the Service may link to third party websites or feeds
                                            that
                                            are
                                            connected
                                            or relevant to the Service. Any link from the Service does not imply that we
                                            endorse,
                                            approve
                                            or recommend, or have responsibility for, those websites or feeds or their
                                            content or
                                            operators.
                                            To the maximum extent permitted by law, we exclude all responsibility or
                                            liability for those
                                            websites or feeds.
                                        </li>
                                    </ol>
                                </p>
                            </li>

                            <li className="main-list">
                                <h4>Confidentiality</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Each party must, unless it has the prior written consent of the other party:
                                            <ol type="a">
                                                <li>keep confidential at all times the Confidential Information of the other
                                            party;</li>
                                                    <li>effect and maintain adequate security measures to safeguard the other
                                            party’s
                                            Confidential Information from unauthorised access or use; and</li>
                                                        <li>disclose the other party’s Confidential Information to its personnel or
                                            professional
                                            advisors on a need to know basis only and, in that case, ensure that any
                                            personnel or
                                            professional advisor to whom it discloses the other party’s Confidential
                                            Information is
                                            aware of, and complies with, clauses 9.1a and 9.1b.</li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">The obligation of confidentiality in clause 9.1 does not apply to any
                                            disclosure
                                            or use
                                            of
                                            Confidential Information:
                                            <ol type="a">
                                                <li>for the purpose of performing a party’s obligations, or exercising a
                                                    party’s rights, under
                                                    these Terms;
                                                </li>
                                                <li>required by law ;
                                                </li>
                                                <li>which is publicly available through no fault of the recipient of the
                                                    Confidential
                                                    Information
                                                    or its personnel;
                                                </li>
                                                <li>which was rightfully received by a party from a third party without
                                                    restriction and
                                                    without
                                                    breach of any obligation of confidentiality; or
                                                </li>
                                                <li>by us if required as part of a bona fide sale of our business
                                                    (assets or
                                                    shares, whether
                                                    in
                                                    whole or in part) to a third party, provided that we enter into a
                                                    confidentiality agreement
                                                    with the third party on terms no less restrictive than this clause
                                                    9.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </p>
                            </li>


                            <li className="main-list">
                                <h4>Warranties</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Each party warrants that it has full power and authority to enter into, and
                                            perform its
                                            obligations
                                            under, these Terms.
                                        </li>
                                        <li className="sub-list">To the maximum extent permitted by law:
                                            <ol type="a">
                                                <li>our warranties are limited to those set out in these Terms, and all
                                                    other conditions,
                                                    guarantees or warranties whether expressed or implied by statute or
                                                    otherwise
                                                    are expressly
                                                    excluded and, to the extent that they cannot be excluded, liability
                                                    for
                                                    them is limited to
                                                    GBP 100.00; and
                                                </li>
                                                <li>we make no representation concerning the quality of the Service and
                                                    do
                                                    not promise that
                                                    the Service will:
                                                    <ol type="i">
                                                        <li>meet your requirements or be suitable for a particular
                                                            purpose,
                                                            including that the
                                                            use of the Service will fulfil or meet any statutory role or
                                                            responsibility you may
                                                            have; or
                                                        </li>
                                                        <li>be secure, free of viruses or other harmful code,
                                                            uninterrupted
                                                            or error free.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">You agree and represent that you are acquiring the Service, and accepting
                                            these
                                            Terms,
                                            for the
                                            purpose of trade. The parties agree that:
                                            <ol type="a">
                                                <li>to the maximum extent permissible by law, any applicable consumer protection legislation does not apply to
                                                    the
                                                    supply of the
                                                    Service or these Terms; and
                                                </li>
                                                <li>it is fair and reasonable that the parties are bound by this clause
                                                    10.3.
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">Where legislation or rule of law implies into these Terms a condition or
                                            warranty that
                                            cannot be
                                            excluded or modified by contract, the condition or warranty is deemed to be
                                            included in
                                            these
                                            Terms. However, our liability for any breach of that condition or warranty
                                            is
                                            limited, at
                                            our
                                            option, to:
                                            <ol type="a">
                                                <li>supplying the Service again; and/or</li>
                                                <li>paying the costs of having the Service supplied again.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                </p>
                            </li>

                            <li className="main-list">
                                <h4>Liability </h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Our maximum aggregate liability under or in connection with these Terms or
                                            relating to
                                            the
                                            Service, whether in contract, tort (including negligence), breach of
                                            statutory
                                            duty or
                                            otherwise,
                                            must not in any Year exceed an amount equal to the Fees
                                            paid by
                                            you
                                            relating
                                            to the Service in the previous 2 months. The
                                            cap in this
                                            clause 11.1 includes the cap set out in clause 10.2a.
                                        </li>
                                        <li className="sub-list">Neither party is liable to the other under or in connection with these Terms
                                            or
                                            the
                                            Service for
                                            any:
                                            <ol type="a">
                                                <li>loss of profit, revenue, savings, business, use, data (including
                                                    Data),
                                                    and/or goodwill;
                                                    or
                                                </li>
                                                <li>consequential, indirect, incidental or special damage or loss of any
                                                    kind.
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">Clause 11.2 does not apply to limit your liability:
                                            <ol type="a">
                                                <li>to pay the Fees;</li>
                                                <li>under the indemnity in clause 6.7; or</li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">Neither party will be responsible, liable, or held to be in breach of these
                                            Terms for
                                            any failure to
                                            perform its obligations under these Terms or otherwise, to the extent that
                                            the
                                            failure is
                                            caused
                                            by the other party failing to comply with its obligations under these Terms,
                                            or
                                            by the
                                            negligence
                                            or misconduct of the other party or its personnel.
                                        </li>
                                        <li className="sub-list">Each party must take reasonable steps to mitigate any loss or damage, cost
                                            or
                                            expense
                                            it may
                                            suffer or incur arising out of anything done or not done by the other party
                                            under or in
                                            connection
                                            with these Terms or the Service.
                                        </li>
                                    </ol>
                                </p>
                            </li>

                            <li className="main-list">
                                <h4>Term, Termination & Suspension</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Unless terminated under this clause 12, these Terms and your right to access
                                            and
                                            use
                                            the
                                            Service:
                                            <ol type="a">
                                                <li>starts on the Start Date; and</li>
                                                <li>continues until a party terminates these terms and your access to and use of the Service.
                                                </li>
                                            </ol>
                                        </li>

                                        <li className="sub-list">Either party may, by notice to the other party, immediately terminate these
                                            Terms and
                                            your right
                                            to access and use the Service if the other party:
                                            <ol type="a">
                                                <li>breaches any material provision of these Terms and the breach is
                                                    not:
                                                    <ol type="i">
                                                        <li>remedied within 10 days of the receipt of a notice from the
                                                            first party requiring it to
                                                            remedy the breach; or
                                                        </li>
                                                        <li>capable of being remedied; or</li>
                                                    </ol>
                                                </li>
                                                <li>becomes insolvent, liquidated or bankrupt, has an administrator,
                                                    receiver, liquidator,
                                                    statutory manager, mortgagee’s or chargee’s agent appointed, becomes
                                                    subject to any

                                                    form of insolvency action or external administration, or ceases to
                                                    continue business for
                                                    any reason.
                                                </li>
                                            </ol>
                                        </li>
                                        <li className="sub-list">You may terminate these Terms and your right to access and use the Service
                                            in accordance with clause 7.6.
                                        </li>
                                        <li className="sub-list">Termination of these Terms does not affect either party’s rights and
                                            obligations that accrued before that termination.
                                        </li>
                                        <li className="sub-list">On termination of these Terms, you must pay all Fees for the provision of
                                            the Service prior to that termination.
                                        </li>
                                        <li className="sub-list">No compensation is payable by us to you as a result of termination of these
                                            Terms for whatever reason.
                                        </li>
                                        <li className="sub-list">Except to the extent that a party has ongoing rights to use Confidential
                                            Information, at the other party’s request following termination of these Terms but subject to clause 12.8,
                                            a party must promptly return to the other party or destroy all Confidential Information of the other party
                                            that is in the first party’s possession or control.
                                        </li>
                                        <li className="sub-list">At any time prior to the date of termination, you may
                                            request:
                                            <ol type="a">
                                                <li>a copy of any Data stored using the Service, provided that you pay
                                                    our reasonable costs of providing that copy. On receipt of that request,
                                                    we must provide a copy of the Data in a common electronic form. We do not
                                                    warrant that the format of the Data will be compatible with any software; and/or
                                                </li>
                                                <li>deletion of the Data stored using the Service, in which case we must use
                                                    reasonable efforts to promptly delete that Data.
                                                </li>
                                            </ol>
                                            To avoid doubt, we are not required to comply with clause 12.8a to the extent that you
                                            have previously requested deletion of the Data.
                                        </li>
                                        <li className="sub-list">Without limiting any other right or remedy available to us, we may restrict
                                            or
                                            suspend
                                            your
                                            access to and use of the Service and/or delete, edit or remove the relevant
                                            Data
                                            if we
                                            consider
                                            that you or any of your personnel have:
                                            <ol type="a">
                                                <li>undermined, or attempted to undermine, the security or integrity of
                                                    the
                                                    Service or any
                                                    Underlying Systems;
                                                </li>
                                                <li>used, or attempted to use, the Service:
                                                    <ol type="i">
                                                        <li>for improper purposes; or</li>
                                                        <li>in a manner, other than for normal operational purposes,
                                                            that
                                                            materially reduces
                                                            the operational performance of the Service;
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>transmitted, inputted or stored any Data that breaches or may breach
                                                    these Terms or
                                                    any third party right (including Intellectual Property Rights and
                                                    privacy rights), or that
                                                    is or
                                                </li>
                                                may be Objectionable, incorrect or misleading; or
                                                <li>otherwise materially breached these Terms.</li>

                                            </ol>
                                        </li>
                                    </ol>
                                </p>
                            </li>

                            <li className="main-list">
                                <h4>General</h4>
                                <p>
                                    <ol className="sub-list">
                                        <li className="sub-list">Neither party is liable to the other for any failure to perform its
                                            obligations
                                            under
                                            these Terms to
                                            the extent caused by Force Majeure.
                                        </li>
                                        <li className="sub-list">No person other than you and us has any right to a benefit under, or to
                                            enforce,
                                            these
                                            Terms.
                                        </li>
                                        <li className="sub-list">For us to waive a right under these Terms, that waiver must be in writing
                                            and
                                            signed by
                                            us.
                                        </li>
                                        <li className="sub-list">Subject to clause 6.4, we are your independent contractor, and no other
                                            relationship
                                            (e.g. joint
                                            venture, agency, trust or partnership) exists under these Terms.
                                        </li>
                                        <li className="sub-list">If we need to contact you, we may do so by email or by posting a notice on
                                            the
                                            Website.
                                            You
                                            agree that this satisfies all legal requirements in relation to written
                                            communications. You
                                            may
                                            give notice to us under or in connection with these Terms by emailing
                                            hello@reminded.io.
                                        </li>
                                        <li className="sub-list">These Terms, and any dispute relating to these Terms or the Service, are
                                            governed by
                                            and
                                            must be interpreted in accordance with the laws of England and Wales. Each party
                                            submits to the
                                            non-exclusive jurisdiction of the Courts of England and Wales in relation to any
                                            dispute connected
                                            with these Terms or the Service.
                                        </li>
                                        <li className="sub-list">Clauses which, by their nature, are intended to survive termination of these
                                            Terms,
                                            including
                                            clauses 6.7, 8, 9, 11, 12.4 to 12.8 and 13.6, continue in force.
                                        </li>
                                        <li className="sub-list">If any part or provision of these Terms is or becomes illegal,
                                            unenforceable, or
                                            invalid, that part
                                            or provision is deemed to be modified to the extent required to remedy the
                                            illegality,
                                            unenforceability or invalidity. If modification is not possible, the part or
                                            provision must
                                            be
                                            treated for all purposes as severed from these Terms. The remainder of these
                                            Terms will be
                                            binding on you.
                                        </li>
                                        <li className="sub-list">These Terms set out everything agreed by the parties relating to the
                                            Service,
                                            and supersede
                                            and cancel anything discussed, exchanged or agreed prior to the Start Date.
                                            The
                                            parties have
                                            not relied on any representation, warranty or agreement relating to the
                                            Service
                                            that is not
                                            expressly set out in these Terms, and no such representation, warranty or
                                            agreement has any
                                            effect from the Start Date.
                                        </li>
                                        <li className="sub-list">You may not assign, novate, subcontract or transfer any right or obligation
                                            under
                                            these Terms
                                            without our prior written consent, that consent not to be unreasonably
                                            withheld.
                                            You remain
                                            liable for your obligations under these Terms despite any approved
                                            assignment,
                                            subcontracting
                                            or transfer.
                                        </li>
                                    </ol>
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};
