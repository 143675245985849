import React from "react";
import APIService from "../../services/api.service";

export default function SubscriptionDetails() {

    const currentUser = APIService.getCurrentUser();

    return (
        <div className="card mt-4" id="subscription-details">
            <div className="card-header pb-0 px-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h5 className="mb-0">Subscription details</h5>
                    </div>
                    <div className="col-6 text-end">
                        <a className="btn bg-gradient-primary mb-0" href="/pricing"><i
                            className="material-icons text-sm">thumb_up_off_alt</i>&nbsp;&nbsp;Upgrade</a>
                    </div>
                </div>
            </div>
            <div className="card-body pt-4 p-3">
                <ul className="list-group">
                    <li className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                        <div className="d-flex flex-column">
                            <span className="mb-2 text-md">Subscription: <span
                                className="text-dark font-weight-bold ms-sm-2">{currentUser.user.subscription_plan.name}</span></span>
                            <span className="mb-2 text-md">Billing cycle: <span
                                className="text-dark ms-sm-2 font-weight-bold text-capitalize">{currentUser.user.subscription_plan.billing_cycle}</span></span>
                            <span className="text-md">Amount: <span
                                className="text-dark ms-sm-2 font-weight-bold">£ {currentUser.user.subscription_plan.price}</span></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
