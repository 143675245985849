import React, {useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";

export default function Pricing() {

    var current_user = APIService.getCurrentUser();
    const [userPlanID, setUserPlanID] = useState(current_user.user.subscription_plan_id);
    window.setUserPlanID = setUserPlanID;

    const openPaymentModal = (plan_id) => {
        window.setPayFormFields({mode: 'initial', new_plan_id: plan_id});
        document.getElementById('payment-modal').style.display = "block";
    }

    const onSelectSubscription = (plan_id) => {

        Swal.fire({
            icon: "question",
            title: "Are you sure?",
            text: "Confirm subscription change",
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false,
        }).then(function(isConfirm) {
            if (isConfirm) {
                selectSubscription(plan_id);
            }
        })

    }

    const selectSubscription = (plan_id) => {

       axios.post(APIService.API_URL + "user/new_subscription", {plan_id: plan_id},
            {headers: {"Authorization": APIService.getHeaders().authorization}})
           .then((response) => {
            if (response.data.errors) {
                var respStr = APIService.errorsArray(response.data.errors);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops! Something went wrong.',
                    text: respStr,
                    customClass: {
                        confirmButton: 'btn btn-primary m-1',
                        cancelButton: 'btn btn-secondary m-1'
                    },
                    buttonsStyling: false
                });
            } else if(response.data.subscriptionId && response.data.clientSecret) {
                window.clientSecret = response.data.clientSecret
                openPaymentModal(plan_id);
            } else {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                setUserPlanID(response.data.user.subscription_plan_id);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your subscription has been updated.',
                    customClass: {
                        confirmButton: 'btn btn-primary m-1',
                        cancelButton: 'btn btn-secondary m-1'
                    },
                    buttonsStyling: false
                });
            }
        })

    };

   

    return (
        <div>
            <div className="container m-0 p-0 mw-100 pricing">
                <div className="mb-5">
                    <div className="col-lg-4 col-md-6 col-7 mx-auto text-center">
                        <div className="nav-wrapper mt-5 position-relative z-index-2">
                            <ul className="nav nav-pills nav-fill flex-row p-1" id="tabs-pricing" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link mb-0 active" id="tabs-iconpricing-tab-1" data-bs-toggle="tab"
                                       href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">
                                        Monthly
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0" id="tabs-iconpricing-tab-2" data-bs-toggle="tab"
                                       href="#annual" role="tab" aria-controls="annual" aria-selected="false">
                                        Annual
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="tab-content tab-space">
                    <div className="tab-pane active" id="monthly">
                        <div className="row">
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span
                                        className="badge rounded-pill bg-dark w-30 mt-n2 mx-auto">Personal</span>
                                    <div className="card-header text-center pt-4 pb-3">
                                        <h3 className="font-weight-bold mt-2">
                                            Free
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 10 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 5 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 1 && <button onClick={() => onSelectSubscription(1)}
                                                className="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 1 && <button
                                                                      className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span
                                        className="badge rounded-pill bg-primary w-30 mt-n2 mx-auto">Pro</span>
                                    <div className="card-header text-center pt-4 pb-3 bg-transparent">
                                        <h3 className="font-weight-bold mt-2">
                                            <small className="text-lg align-top me-1">£</small>4.99<small
                                            className="text-lg">/mo</small>
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 40 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 25 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">24/7 email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 3 && <button onClick={() => onSelectSubscription(3)}
                                                                      className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 3 && <button
                                            className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span
                                        className="badge rounded-pill bg-dark w-30 mt-n2 mx-auto">Business</span>
                                    <div className="card-header text-center pt-4 pb-3">
                                        <h3 className="font-weight-bold mt-2">
                                            <small className="text-lg align-top me-1">£</small>9.99<small
                                            className="text-lg">/mo</small>
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 95 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 50 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 5 && <button onClick={() => onSelectSubscription(5)}
                                                                      className="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 5 && <button
                                            className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="annual">
                        <div className="row">
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span className="badge rounded-pill bg-dark w-30 mx-auto">Personal</span>
                                    <div className="card-header text-center pt-4 pb-3">
                                        <h3 className="font-weight-bold mt-2">
                                            Free
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 10 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 5 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 1 && <button onClick={() => onSelectSubscription(1)}
                                                                      className="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 1 && <button
                                            className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span className="badge rounded-pill bg-primary w-30 mx-auto">Pro</span>
                                    <div className="card-header text-center pt-4 pb-3 bg-transparent">
                                        <h3 className="font-weight-bold mt-2">
                                            <small className="text-lg align-top me-1">£</small>49.99<small
                                            className="text-lg">/yr</small>
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 40 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 25 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">24/7 email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">close</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 4 && <button onClick={() => onSelectSubscription(4)}
                                                                      className="btn btn-icon bg-gradient-primary d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 4 && <button
                                            className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <div className="card shadow-lg">
                                    <span
                                        className="badge rounded-pill bg-dark w-30 mx-auto">Business</span>
                                    <div className="card-header text-center pt-4 pb-3">
                                        <h3 className="font-weight-bold mt-2">
                                            <small className="text-lg align-top me-1">£</small>99.99<small
                                            className="text-lg">/yr</small>
                                        </h3>
                                    </div>
                                    <div className="card-body text-lg-start text-center pt-0">
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 95 ongoing activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Up to 50 contacts per activity</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited archived activities</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Unlimited lists</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email & SMS reminders </span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Awards and badges</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Activity tracking & analysis</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Email support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Priority support</span>
                                        </div>
                                        <div className="d-flex justify-content-lg-start justify-content-center p-2">
                                            <i className="material-icons my-auto">done</i>
                                            <span className="ps-3">Detailed historical logs</span>
                                        </div>
                                        {userPlanID !== 6 && <button onClick={() => onSelectSubscription(6)}
                                                                      className="btn btn-icon bg-gradient-dark d-lg-block mt-3 mb-0">
                                            Select
                                            <i className="fas fa-arrow-right ms-1"></i>
                                        </button>}
                                        {userPlanID === 6 && <button
                                            className="btn btn-icon btn-outline-secondary d-lg-block mt-3 mb-0">
                                            Your current subscription
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-3 mb-5">
                <div className="row mt-5">
                    <div className="col-md-6 mx-auto text-center">
                        <h3>Frequently Asked Questions</h3>
                        <p>Pay-As-You-Go pricing. Cancel anytime. 15% discount for annual payments.</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-8 mx-auto">
                        <div className="accordion" id="accordionRental">
                            <div className="accordion-item my-2">
                                <h5 className="accordion-header" id="headingOne">
                                    <button className="accordion-button border-bottom font-weight-bold" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                        Why are there limits on the number of activities and contacts?
                                        <i className="collapse-close material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">add</i>
                                        <i className="collapse-open material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">remove</i>
                                    </button>
                                </h5>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne" data-bs-parent="#accordionRental">
                                    <div className="accordion-body text-sm opacity-8">
                                        Reminders are sent via email and SMS which have cost implications. For us to offer
                                        free or reasonable charges we need to limit the number of reminders each subscription is able
                                        to generate.
                                        <br/><br/>
                                        We regularly review our subscription plans and charges and will always increase the limits when
                                        possible.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item my-2">
                                <h5 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button border-bottom font-weight-bold" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo">
                                        Can I change or cancel my subscription?
                                        <i className="collapse-close material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">add</i>
                                        <i className="collapse-open material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">remove</i>
                                    </button>
                                </h5>
                                <div id="collapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="headingTwo"
                                     data-bs-parent="#accordionRental">
                                    <div className="accordion-body text-sm opacity-8">
                                        Yes. You can change or cancel at anytime. Old and/or new charges will be
                                        prorated based on your usage to date.
                                        <br/><br/>
                                        To move to a lower subscription you will need to reduce the number of
                                        activities currently in progress and contacts in each activity to the lower limits.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item my-2">
                                <h5 className="accordion-header" id="headingThree">
                                    <button className="accordion-button border-bottom font-weight-bold" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Are my payment details safe?
                                        <i className="collapse-close material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">add</i>
                                        <i className="collapse-open material-icons text-sm font-weight-bold pt-1 position-absolute end-0 me-3">remove</i>
                                    </button>
                                </h5>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionRental">
                                    <div className="accordion-body text-sm opacity-8">
                                        Yes they are. We use Stripe to process card payments via encrypted channels.
                                        Stripe are a fully regulated, PCI compliant, international payments processor.
                                        <br/><br/>
                                        We do not handle or process your payment details directly.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
