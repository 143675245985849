import axios from "axios";
import Swal from "sweetalert2";

var AUTH_URL = "http://0.0.0.0:3000/";
var API_URL = "http://0.0.0.0:3000/api/v1/";
var STRIPE_PUB_KEY = 'pk_test_51IF8TIGawpnSaMc8r1dCZzNfYuVhOzZqMnTzFGPQTl1PlcYEpANCAkhU7OEOIpOkso9mJsHmsLeNE2EdU2BlaXtL00zcMSYEWc';

if (window.location.hostname === "www.reminded.io") {
    AUTH_URL = "https://api.repeatdo.com/";
    API_URL = "https://api.repeatdo.com/api/v1/";
    STRIPE_PUB_KEY = 'pk_live_51IF8TIGawpnSaMc8sLNLvyoDBbe20oLXTcERDwYAXGrMFTk8BMrmLengAJP1LCC0gGgI3yZzBxer8L9JnkWehAI800SbfptnrZ';
} else if (window.location.hostname === "do6nh5wbp7uxd.cloudfront.net") {
    AUTH_URL = "https://api-staging.repeatdo.com/";
    API_URL = "https://api-staging.repeatdo.com/api/v1/";
} else if(window.location.hostname === "192.168.43.51"){
    AUTH_URL = "http://192.168.43.51:3000/";
    API_URL = "http://192.168.43.51:3000/api/v1/";
}


const logout = () => {

    if (getHeaders()) {
        axios.delete(AUTH_URL + "sign_out",
            {headers: {"Authorization": getHeaders().authorization}}
        ).then((response) => {
            // do nothing
        }).catch((error) => {
            // do nothing
        });
    }

    localStorage.removeItem("headers");
    localStorage.removeItem("tour_status");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user_tasklists");
    sessionStorage.removeItem("user_assignees");
};

const getCurrentUser = () => {
    return JSON.parse(sessionStorage.getItem("user"));
};

const resetCurrentUser = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user/get_user",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                window.location.assign("/dashboard");
            }

        }).catch((error) => {
            // do nothing
        });
    }

}

const getHeaders = () => {
    return JSON.parse(localStorage.getItem("headers"));
};

const getTasklists = () => {
    return JSON.parse(sessionStorage.getItem("user_tasklists"));
};

const getActiveTasks = () => {
    return sessionStorage.getItem("active_tasks");
}

const resetTasklists = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user_tasklists/get",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                sessionStorage.setItem("user_tasklists", JSON.stringify(response.data.user_tasklists));
                sessionStorage.setItem("active_tasks", response.data.active_tasks);
                window.updateCategoriesData(response.data.user_tasklists);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteTasklist = (user_tasklist_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_tasklists/delete", {"user_tasklist_id": user_tasklist_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                if (window.location.pathname.includes("/list/")) {
                    sessionStorage.removeItem("user_tasklists");
                    window.location.assign("/lists");
                } else {
                    resetTasklists();
                }
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const getAssignees = () => {
    return JSON.parse(sessionStorage.getItem("user_assignees"));
};

const resetAssignees = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user_assignees/get",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                sessionStorage.setItem("user_assignees", JSON.stringify(response.data.user_assignees));
                window.updateAssigneesData(getAssignees());
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteAssignee = (assignee_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_assignees/delete", {"user_assignee_id": assignee_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                resetAssignees();
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const getTags = () => {
    if(getCurrentUser()) {
        return JSON.parse(sessionStorage.getItem("user")).user.user_tags;
    }
};


const resetTasklistItems = () => {

    if (getHeaders() && window.actveListID) {

        axios.get(API_URL + "user_tasklists/get_by_id", {
            params: {"id": window.actveListID.id},
            headers: {"Authorization": getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                return [];
            } else {
                window.updateListData(response.data.user_tasklist);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteTasklistItem = (task_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_tasks/delete", {"user_task_id": task_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                resetTasklistItems();
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const resendTaskInvitations = (task_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_tasks/resend_invitations", {"user_task_id": task_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                // do nothing
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const errorsArray = (errorObj) => {

    var respStr = 'Oh no! ';
    for (var key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {

            var emoji = '&#128073;';
            if (key === 'email') {
                emoji = '&#128075;';
            } else if (key === 'password') {
                emoji = '&#128551;';
            }

            if (errorObj[0]) {
                if (errorObj[0].attribute === 'unlock_token' && errorObj[0].type === 'invalid') {
                    respStr = respStr + 'unlock_token' + "&nbsp;" + emoji + "&nbsp; " + 'is invalid.' + "<br/><br/>";
                }
            } else {
                respStr = respStr + key + "&nbsp;" + emoji + "&nbsp; " + errorObj[key] + "<br/><br/>";
            }
        }
    }
    return respStr;
}

const errorResponse = (error) => {

    var respStr = '';
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
        logout();
        window.location.assign("/login");
    } else if (error.response && error.response.data.error) {
        respStr = error.response.data.error;
    } else {
        respStr = "Oops! Something went wrong. Please try again.";
    }

    return respStr;

}

const getTourStatus = () => {
    return localStorage.getItem("tour_status");
}

const setTourStatus = () => {
    localStorage.setItem("tour_status", true)
}

const showUpgradeAlert = (active_num_tasks, limitActivities) => {
    Swal.fire({
        title: 'Oops!',
        imageUrl: '/dashboard/images/undraw_Check_boxes_re_v40f.svg',
        imageWidth: 200,
        html: 'You have ' + active_num_tasks + ' activities that are still in progress.<br/><br/> ' +
            'Your subscription plan has a limit of ' + limitActivities + '.<br/><br/> ' +
            'Upgrade to add more.</span>',
        showCancelButton: true,
        confirmButtonText: "Upgrade",
        cancelButtonText: "Not now",
        customClass: {
            confirmButton: 'btn btn-primary m-1',
            cancelButton: 'btn btn-secondary m-1'
        },
        buttonsStyling: false
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            window.location.assign("/pricing");
        }
    });
}

export default {
    AUTH_URL,
    API_URL,
    STRIPE_PUB_KEY,
    logout,
    getCurrentUser,
    resetCurrentUser,
    getHeaders,
    getTasklists,
    getActiveTasks,
    resetTasklists,
    deleteTasklist,
    resetTasklistItems,
    deleteTasklistItem,
    resendTaskInvitations,
    getAssignees,
    resetAssignees,
    deleteAssignee,
    getTags,
    errorsArray,
    errorResponse,
    setTourStatus,
    getTourStatus,
    showUpgradeAlert
};

