import React, {useEffect, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Joyride, {ACTIONS, STATUS} from "react-joyride";
import RemindersList from "./RemindersList";

export default function DashboardStats() {

    var steps = [
        {
            target: 'body',
            content: <span><h5>Welcome onboard</h5>
                 <p>Congratulations on making it this far!</p>
                <img src="/dashboard/images/undraw_Landing_page_re_6xev.svg" className="w-70"/>
                <p>Before you begin, how about a quick tour?</p>
               </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: '.add-button-dropdown',
            content: <span><h5>Add an activity or a list</h5>
                <img src="/dashboard/images/undraw_Add_tasks_re_s5yj.svg" className="w-50"/>
               </span>,
        },
        {
            target: '.current-reminders',
            content: <span><h5>Reminders</h5>
                <p>View and and complete your reminders.</p>
                <img src="/dashboard/images/undraw_Plans_re_s9mf.svg" className="w-50"/>
               </span>,
        },
        {
            target: 'body',
            content: <span><h5>Lists</h5>
                <p>Use lists to organise your activities.</p>
                <img src="/dashboard/images/undraw_task_list_6x9d.svg" className="w-50"/>
                </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: '.card.awards',
            content: <span><h5>Status and Progress</h5>
                <p>See how your activities are doing with status and stats.</p>
                <img src="/dashboard/images/undraw_all_the_data_re_hh4w.svg" className="w-50"/>
               </span>,
        },
        {
            target: 'body',
            content: <span><h5>Contacts</h5>
                <p>Manage contact details of your clients.</p>
                <img src="/dashboard/images/undraw_team_re_0bfe.svg" className="w-50"/>
                </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: 'body',
            content: <span><h5>That's it for now</h5>
                <p>Get in touch at anytime if you need any help. We'd love to assist.</p>
                <img src="/dashboard/images/undraw_creative_team_r90h.svg" className="w-70"/>
               </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
    ];
    var showTour = false;

    var tourStatus = APIService.getTourStatus();
    var currentUser = APIService.getCurrentUser();
    if (tourStatus !== 'true' && currentUser && currentUser.user.sign_in_count < 50) {
        showTour = true;
    }

    const [runTour, setRunTour] = useState(showTour);

    function startTour() {
        setRunTour(true);
    }

    window.startTour = startTour;

    const handleJoyrideCallback = (dt) => {
        const {status, action} = dt;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const finishedActions = [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP];

        if (finishedStatuses.includes(status) || finishedActions.includes(action)) {
            setRunTour(false);
            APIService.setTourStatus();
        }
    };

    const [userStats, updateUserStats] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(APIService.API_URL + "user/get_user_stats", {
            headers: {"Authorization": APIService.getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateUserStats(response.data.user_stats);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, []);

    if (loading) {
        return <div/>;
    }

    return (
        <div>

            <div className="mb-5">
                <div className="row mt-2">
                    <div className="col-sm-4 mt-3">
                        <div className="card awards">
                            <div className="card-header mx-4 p-3 text-center">
                                <a href="/reminders"><div
                                    className="icon icon-shape icon-lg bg-gradient-faded-success shadow text-center rounded-circle position-relative">
                                    <i className="material-icons opacity-10">alarm</i>
                                    <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{userStats.current_reminders}</span>
                                </div></a>
                            </div>
                            <div className="card-body pt-0 p-3 text-center">
                                <h6 className="text-center mb-0"><a href="/reminders">Current reminders</a></h6>
                                <span className="text-xs d-none d-md-block">Your current reminders</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <div className="card">
                            <div className="card-header mx-4 p-3 text-center">
                                <a href="/reminders/late"><div
                                    className="icon icon-shape icon-lg bg-gradient-faded-danger shadow text-center rounded-circle position-relative">
                                    <i className="material-icons opacity-10">alarm</i>
                                    <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{userStats.late_reminders}</span>
                                </div></a>
                            </div>
                            <div className="card-body pt-0 p-3 text-center">
                                <h6 className="text-center mb-0"><a href="/reminders/late">Overdue reminders</a></h6>
                                <span className="text-xs d-none d-md-block">Your overdue reminders</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <div className="card">
                            <div className="card-header mx-4 p-3 text-center">
                                <a href="/reminders/upcoming"><div
                                    className="icon icon-shape icon-lg bg-gradient-faded-info shadow text-center rounded-circle position-relative">
                                    <i className="material-icons opacity-10">alarm</i>
                                    <span className="position-absolute top-5 start-100 translate-middle badge
                                    rounded-pill bg-gradient-primary border border-white small py-1 px-2">{userStats.upcoming_reminders}</span>
                                </div></a>
                            </div>
                            <div className="card-body pt-0 p-3 text-center">
                                <h6 className="text-center mb-0"><a href="/reminders/upcoming">Upcoming reminders</a></h6>
                                <span className="text-xs d-none d-md-block">Your upcoming reminders</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="mb-2">
                        <div className="mx-auto text-center current-reminders">
                            <div className="nav-wrapper position-relative z-index-2">
                                <ul className="nav nav-pills nav-fill flex-row p-1" id="tabs-pricing" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link mb-0 active" id="tabs-iconpricing-tab-1" data-bs-toggle="tab"
                                           href="#current" role="tab" aria-controls="monthly" aria-selected="true">
                                            <i className="material-icons-round align-middle mb-1">alarm</i> Reminders
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link mb-0" id="tabs-iconpricing-tab-2" data-bs-toggle="tab"
                                           href="#late" role="tab" aria-controls="annual" aria-selected="false">
                                            <i className="material-icons-round align-middle mb-1">schedule</i> Overdue reminders
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content tab-space">
                        <div className="tab-pane active" id="current">
                            <div className="card">
                                <RemindersList mode="current" />
                            </div>
                        </div>
                        <div className="tab-pane" id="late">
                            <div className="card">
                                <RemindersList mode="late" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Joyride run={runTour}
                     callback={handleJoyrideCallback}
                     scrollToFirstStep={true}
                     steps={steps}
                     continuous={true}
                     showProgress={true}
                     showSkipButton={true}
                     disableOverlayClose={true}
                     locale={{skip: <span className="text-secondary">Skip tour</span>, last: 'Done'}}
                     styles={{
                         options: {
                             primaryColor: '#E91E63',
                             width: 400,
                             zIndex: 14000
                         }
                     }}/>
        </div>
    )
}