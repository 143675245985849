import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import axios from "axios";
import APIService from "../../services/api.service";

class CheckoutForm extends React.Component {

    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        const {stripe, elements, mode, plan_id} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (mode === 'initial') {

            // Create payment method and confirm payment intent.
            stripe.confirmCardPayment(window.clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {},
                }
            }).then((result) => {
                if (result.error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops! Something went wrong.',
                        text: result.error.message,
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                } else {
                    window.setUserPlanID(plan_id);
                    // TODO: IF THIS RUNS BEFORE THE STRIPE WEBHOOK IT WILL GET THE OLD USER OBJECT
                    sessionStorage.removeItem("user");
                    this.onClickModalClose();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your payment is successful. Your subscription has been updated.',
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                }
            });
        } else if (mode === 'change') {

            stripe.createToken(cardElement).then(function (result) {
                if (result.error) {
                    // Inform the customer that there was an error.
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops! Something went wrong.',
                        text: result.error.message,
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                } else {
                    // Send the token to your server.
                    axios.post(APIService.API_URL + "user/change_payment_method", {token: result.token},
                        {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {
                        if (response.data.errors) {
                            var respStr = APIService.errorsArray(response.data.errors);
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops! Something went wrong.',
                                text: respStr,
                                customClass: {
                                    confirmButton: 'btn btn-primary m-1',
                                    cancelButton: 'btn btn-secondary m-1'
                                },
                                buttonsStyling: false
                            });
                        } else {
                            sessionStorage.setItem("user", JSON.stringify(response.data));
                            window.setPayMethodCurrentUser(APIService.getCurrentUser());
                            document.getElementById('payment-modal').style.display = "none";
                            Swal.fire({
                                icon: 'success',
                                title: 'Success!',
                                text: 'Your payment card has been updated.',
                                customClass: {
                                    confirmButton: 'btn btn-primary m-1',
                                    cancelButton: 'btn btn-secondary m-1'
                                },
                                buttonsStyling: false
                            });
                        }
                    })
                }
            });

        }

    };

    onClickModalClose = () => {
        var modal = document.getElementById('payment-modal');
        modal.style.display = "none";
    };

    render() {
        const {stripe} = this.props;
        const {mode} = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                    <h4 className="modal-title">Add card details</h4>
                    <button className="close-btn btn btn-icon" type="button" onClick={this.onClickModalClose}>
                        <i className="material-icons">cancel</i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-div">
                        <div className="input-group-outline">
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-div text-center mb-1">
                    <div className="input-group input-group-outline m-auto d-flex text-dark w-135px">
                        <i className="material-icons text-lg me-2">lock</i>
                        <span className="text-sm">Secure payments</span>
                    </div>
                </div>
                <div className="form-div text-center">
                    <div className="input-group input-group-outline d-inline">
                        <img src="/dashboard/images/Powered-by-Stripe-blurple.png" width="150px"/>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" disabled={!stripe}>
                        {mode === 'initial' && 'Pay'}
                        {mode === 'change' && 'Save'}
                    </button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                            onClick={this.onClickModalClose}>Cancel
                    </button>
                </div>
            </form>
        );
    }
}


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(APIService.STRIPE_PUB_KEY);

const UpdatePackage = () => {

    const [payMode, setPayMode] = useState("");
    const [newPlanID, setNewPlanID] = useState("");

    const setPayFormFields = (data) => {
        setPayMode(data.mode);
        setNewPlanID(data.new_plan_id);
    }

    window.setPayFormFields = setPayFormFields;

    return (
        <div className="modal" tabIndex="-1" role="dialog" id="payment-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({elements, stripe}) => (
                                <CheckoutForm elements={elements} stripe={stripe} mode={payMode} plan_id={newPlanID}/>
                            )}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </div>
        </div>
    );
};

export default UpdatePackage;