import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Swal from 'sweetalert2';

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function AddNudgeModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const [description, setDescription] = useState("");

    const descrMaxChars = 150;

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("nudge-description-char-count").innerText = e.target.value.length;
    };

    const onClickModalClose = () => {
        var modal = document.getElementById('add-nudge-modal');
        modal.style.display = "none";

        document.getElementById("nudge-description-char-count").innerText = '0';

        setDescription("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");



    };


    const addNewNudge = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {


            var api_endpoint = "task_nudges/add";
            var payload = {"user_task_id": window.activeActivityID, "message": description };

            axios.post(APIService.API_URL + api_endpoint, payload,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {

                        onClickModalClose();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Your message is being sent.',
                            customClass: {
                                confirmButton: 'btn btn-primary m-1',
                                cancelButton: 'btn btn-secondary m-1'
                            },
                            buttonsStyling: false
                        });

                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal" tabIndex="-1" role="dialog" id="add-nudge-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewNudge} className="navbar-form" id="form-add-nudge-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">Send message</h4>
                            <button className="close-btn btn btn-icon" type="button" onClick={onClickModalClose}>
                                <i className="material-icons">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <p>Send a message to contacts.</p>
                            <p className="text-sm">Message will only be sent to contacts that have accepted the invitation.</p>

                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="description" className="text-normal text-dark">Your message
                                        <Textarea id="form-add-nudge-description" name="description"
                                                  value={description} maxLength={descrMaxChars}
                                                  className="form-control" onChange={onChangeDescription}
                                                  validations={[required]}
                                                  placeholder="Message" rows="3"
                                        />
                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="nudge-description-char-count">0</span> / {descrMaxChars} chars.
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Send</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>

                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}