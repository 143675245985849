import React from "react";

export default function PrivacyPolicy() {

    return (
        <div>
            <div className="card mt-3 mb-5 p-1">
                <div className="row mt-5 mb-5">
                    <div className="col-md-7 mx-auto">

                        <h3 className="privacy-policy-h1">Privacy Policy</h3>
                        <p>Last Updated On 30-Sep-2021<br/>
                            Effective Date 30-Sep-2021
                        </p>

                        <p className="privacy-policy-p">
                            This Privacy Policy describes the policies of ZURI CONSULTING LIMITED (trading as Reminded) on the collection,
                            use and disclosure of your information that we collect
                            when you use our website ( www.reminded.io ) and mobile apps.
                            (the “Service”). By accessing or using
                            the Service, you are consenting to the collection, use and
                            disclosure of your information in accordance with this
                            Privacy Policy. If you do not consent to the same,
                            please do not access or use the Service.
                        </p>

                        <p className="privacy-policy-p">
                            We may modify this Privacy Policy at any time without
                            any prior notice to you and will post the revised
                            Privacy Policy on the Service. The revised Policy will
                            be effective on the Effective Date stated on the new policy and your
                            continued access or use of the Service after such time
                            will constitute your acceptance of the revised Privacy
                            Policy. We therefore recommend that you periodically
                            review this page.
                        </p>

                        <ol >
                            <li><h4 className="privacy-policy-h4">
                                    Information we collect:
                                </h4>

                                <p className="privacy-policy-p">
                                    We will collect and process the following
                                    personal information about you:
                                </p>
                                <p>
                                    <ol type="a">
                                        <li>Name</li>
                                        <li>Email</li>
                                        <li>Mobile phone number</li>
                                        <li>Payment information</li>
                                        <li>IP address</li>
                                    </ol>
                                </p>
                            </li>

                            <li>
                                <h4 className="privacy-policy-h4">
                                    How we collect your information:
                                </h4>
                                <p className="privacy-policy-p">
                                    We collect/receive information about you in the
                                    following manner:
                                </p>
                                <p>
                                    <ol type="a">
                                        <li>When a user fills in the registration form or otherwise submits personal
                                            information
                                        </li>
                                        <li>Adds data</li>
                                        <li>Interacts with our websites or mobile apps</li>
                                        <li>Using tracking and analytics software on our platforms</li>
                                        <li>From public or third-party sources</li>
                                        <li>When another user adds data including your name, email address or phone number</li>
                                    </ol>
                                </p>
                            </li>


                            <li>
                                <h4 className="privacy-policy-h4">
                                    How we use your information:
                                </h4>
                                <p className="privacy-policy-p">
                                    We will use the information that we collect
                                    about you for the following purposes:
                                </p>
                                <p>
                                    <ol type="a">
                                        <li>Create a user account</li>
                                        <li>Provide our software service through our website or mobile app</li>
                                        <li>Enforce our Terms & Conditions</li>
                                        <li>Processing payment</li>
                                        <li>Offering customer support</li>
                                        <li>Manage your subscription</li>
                                        <li>Site protection</li>
                                        <li>User to user communication</li>
                                        <li>Manage user account</li>
                                        <li>Tracking and analysing user activity</li>
                                    </ol>
                                </p>
                                <p className="privacy-policy-p">
                                    If we want to use your information for any other
                                    purpose, we will ask you for consent and will
                                    use your information only on receiving your
                                    consent and then, only for the purpose(s) for
                                    which grant consent unless we are required to do
                                    otherwise by law.
                                </p>
                            </li>


                            <li>
                                <h4 className="privacy-policy-h4">
                                    How we share your information
                                </h4>

                                <p className="privacy-policy-p">
                                    We will not transfer your personal information
                                    to any third party without seeking your consent,
                                    except in limited circumstances as described
                                    below:
                                </p>
                                <p>
                                    <ol type="a">
                                        <li>Notifying other users of tasks, activities and reminders that you have invited them to</li>
                                        <li>Analytics</li>
                                        <li>Data collection & process</li>
                                    </ol>
                                </p>
                                <p className="privacy-policy-p">
                                    We require such third party’s to use the
                                    personal information we transfer to them only
                                    for the purpose for which it was transferred and
                                    not to retain it for longer than is required for
                                    fulfilling the said purpose.
                                </p>
                                <p className="privacy-policy-p">
                                    We may also disclose your personal information
                                    for the following: (1) to comply with applicable
                                    law, regulation, court order or other legal
                                    process; (2) to enforce your agreements with us,
                                    including this Privacy Policy; or (3) to respond
                                    to claims that your use of the Service violates
                                    any third-party rights. If the Service or our
                                    company is merged or acquired with another
                                    company, your information will be one of the
                                    assets that is transferred to the new owner.
                                </p>
                            </li>


                            <li>
                                <h4 className="privacy-policy-h4">
                                    Retention of your information
                                </h4>
                                <p className="privacy-policy-p">
                                    We will retain your personal information with us
                                    for
                                    90 days to 2 years after users terminate their accounts
                                    or for as
                                    long as we need it to fulfill the purposes for
                                    which it was collected as detailed in this
                                    Privacy Policy. We may need to retain certain
                                    information for longer periods such as
                                    record-keeping / reporting in accordance with
                                    applicable law or for other legitimate reasons
                                    like enforcement of legal rights, fraud
                                    prevention, etc. Residual anonymous information
                                    and aggregate information, neither of which
                                    identifies you (directly or indirectly), may be
                                    stored indefinitely.
                                </p>
                            </li>

                            <li>
                                <h4 className="privacy-policy-h4">
                                    Your rights
                                </h4>
                                <p className="privacy-policy-p">
                                    Depending on the law that applies, you may have
                                    a right to access and rectify or erase your
                                    personal data or receive a copy of your personal
                                    data, restrict or object to the active
                                    processing of your data, ask us to share (port)
                                    your personal information to another entity,
                                    withdraw any consent you provided to us to
                                    process your data, a right to lodge a complaint
                                    with a statutory authority and such other rights
                                    as may be relevant under applicable laws. To
                                    exercise these rights, you can write to us at
                                    hello@reminded.io.
                                    We will respond to your
                                    request in accordance with applicable law.
                                </p>


                                <p className="privacy-policy-p">
                                    Do note that if you do not allow us to collect
                                    or process the required personal information or
                                    withdraw the consent to process the same for the
                                    required purposes, you may not be able to access
                                    or use the services for which your information
                                    was sought.
                                </p>
                            </li>
                            <li>
                                <h4 className="privacy-policy-h4">
                                    Cookies
                                </h4>
                                <p className="privacy-policy-p">
                                    "Cookies" are data files that are placed on your device or computer and often include
                                    an anonymous unique identifier. For more information about cookies, and how to disable
                                    cookies, visit http://www.allaboutcookies.org.
                                </p>
                                <p>We use cookies to track and hold data that we use to offer our service through our
                                    website and mobile apps.</p>
                            </li>

                            <li>
                                <h4 className="privacy-policy-h4">
                                    Security
                                </h4>
                                <p className="privacy-policy-p">
                                    The security of your information is important to
                                    us and we will use reasonable security measures
                                    to prevent the loss, misuse or unauthorized
                                    alteration of your information under our
                                    control. However, given the inherent risks, we
                                    cannot guarantee absolute security and
                                    consequently, we cannot ensure or warrant the
                                    security of any information you transmit to us
                                    and you do so at your own risk.
                                </p>
                            </li>


                            <li>
                                <h4>
                                    Grievance / Data Protection Officer
                                </h4>
                                <p>
                                    If you have any queries or concerns about the
                                    processing of your information that is available
                                    with us, you may email us at hello@reminded.io.
                                    We will address your concerns in accordance with applicable law.
                                </p>
                            </li>
                        </ol>
                        <p>
                            Privacy Policy generated with <a target="_blank"
                                                             href="https://www.cookieyes.com/?utm_source=PP&utm_medium=footer&utm_campaign=UW">CookieYes</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
