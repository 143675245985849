import React, {useState} from "react";
import APIService from "../../services/api.service";

const Profile = () => {

    const convert_null = (value) => {
        return (value === null) ? "" : value;
    }

    const [profileCurrentUser, setProfileCurrentUser] = useState(APIService.getCurrentUser());

    window.setProfileCurrentUser = setProfileCurrentUser;


    return (
        <div className="card card-body" id="view-profile">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm-auto col-4">
                    <div className="avatar avatar-xl position-relative">

                    </div>
                </div>
                <div className="col-sm-auto col-8 my-auto">
                    <div className="h-100">
                        <h5 className="profile-email mb-1 font-weight-bolder">
                            {profileCurrentUser.user.email}
                        </h5>
                        <p className="mb-0 font-weight-normal text-sm">
                            {convert_null(profileCurrentUser.user.first_name) + ' ' + convert_null(profileCurrentUser.user.last_name)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
