import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Swal from 'sweetalert2';

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function AddListModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [listID, setListID] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const descrMaxChars = 150;
    const titleMaxChars = 50;

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
        document.getElementById("list-title-char-count").innerText = e.target.value.length;
    };

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("list-description-char-count").innerText = e.target.value.length;
    };

    const onChangeListID = (e) => {
        setListID(e.target.value);
    };

    const onClickModalClose = () => {
        var modal = document.getElementById('add-list-modal');
        modal.style.display = "none";

        document.getElementById("list-title-char-count").innerText = '0';
        document.getElementById("list-description-char-count").innerText = '0';

        setTitle("");
        setDescription("");
        setListID("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

        var listColor = document.getElementsByName("list-color");
        for(var i=0;i<listColor.length;i++) {
            listColor[i].checked = false;
        }


    };

    const onClickListDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Your list and all the activities in it will be deleted.',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteTasklist(listID);
            }
        });
    }

    const setListModalFormFields = (data) => {

        setListID(data.data.id);
        setTitle(data.data.title);
        setDescription(data.data.description);

        document.getElementById("list-title-char-count").innerText = data.data.title.length;
        document.getElementById("list-description-char-count").innerText = data.data.description.length;

        var listColor = document.getElementsByName("list-color");
        for(var i=0;i<listColor.length;i++) {
            if(listColor[i].value === data.data.color) {
                listColor[i].checked = true;
            }
        }
    }

    window.setListModalFormFields = setListModalFormFields;

    const addNewList = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var color = "";
            if(document.querySelector('input[name="list-color"]:checked')) {
                color = document.querySelector('input[name="list-color"]:checked').value;
            }

            var api_endpoint = "user_tasklists/add";
            var tasklist = {"user_tasklist_id": listID, "tasklist": { "title": title, "description": description, "color":color}};

            if(listID !== ""){
                api_endpoint = "user_tasklists/update";
            }

            axios.post(APIService.API_URL + api_endpoint, tasklist,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    if(!listID){
                        sessionStorage.removeItem("user_tasklists");
                        window.location.assign("/list/"+response.data.user_tasklist_id);
                    } else {
                        APIService.resetTasklists();
                        APIService.resetTasklistItems();
                        onClickModalClose();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Your tasklist has been saved.',
                            customClass: {
                                confirmButton: 'btn btn-primary m-1',
                                cancelButton: 'btn btn-secondary m-1'
                            },
                            buttonsStyling: false
                        });
                    }
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal" tabIndex="-1" role="dialog" id="add-list-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewList} className="navbar-form" id="form-add-list-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">Add a list</h4>
                            <button className="close-btn btn btn-icon" type="button" onClick={onClickModalClose}>
                                <i className="material-icons">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <p>Create a list to group your activities by category or project.</p>
                            <input type="hidden" name="list-id" value={listID} onChange={onChangeListID}/>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="title" className="text-normal text-dark">Give your list a nice, catchy title
                                        <Input type="text" id="form-add-list-title" name="title"
                                               className="form-control"
                                               value={title} maxLength={titleMaxChars} placeholder="Title here..."
                                               onChange={onChangeTitle}
                                               validations={[required]}/>
                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="list-title-char-count">0</span> / {titleMaxChars} chars.
                                </div>
                            </div>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="description" className="text-normal text-dark">How about a few notes to describe it? (optional)
                                        <Textarea id="form-add-list-description" name="description"
                                                  value={description} maxLength={descrMaxChars}
                                                  className="form-control" onChange={onChangeDescription}
                                                  placeholder="Short description (optional)..." rows="3"
                                        />
                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="list-description-char-count">0</span> / {descrMaxChars} chars.
                                </div>
                            </div>
                            <div className="form-div">
                                <label htmlFor="color" className="text-normal text-dark">And a touch of color...</label>
                                <div className="radio-color-picker">


                                    <input type="radio" name="list-color" id="green" value="green"/>
                                    <label htmlFor="green"><span className="green"></span></label>

                                    <input type="radio" name="list-color" id="yellow" value="yellow"/>
                                    <label htmlFor="yellow"><span className="yellow"></span></label>

                                    <input type="radio" name="list-color" id="olive" value="olive"/>
                                    <label htmlFor="olive"><span className="olive"></span></label>

                                    <input type="radio" name="list-color" id="orange" value="orange"/>
                                    <label htmlFor="orange"><span className="orange"></span></label>

                                    <input type="radio" name="list-color" id="teal" value="teal"/>
                                    <label htmlFor="teal"><span className="teal"></span></label>

                                    <input type="radio" name="list-color" id="blue" value="blue"/>
                                    <label htmlFor="blue"><span className="blue"></span></label>

                                    <input type="radio" name="list-color" id="violet" value="violet"/>
                                    <label htmlFor="violet"><span className="violet"></span></label>

                                    <input type="radio" name="list-color" id="purple" value="purple"/>
                                    <label htmlFor="purple"><span className="purple"></span></label>

                                    <input type="radio" name="list-color" id="pink" value="pink"/>
                                    <label htmlFor="pink"><span className="pink"></span></label>

                                    <input type="radio" name="list-color" id="red" value="red"/>
                                    <label htmlFor="red"><span className="red"></span></label>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {listID && <button type="button" className="btn btn-outline-danger" data-dismiss="modal"
                                    onClick={onClickListDelete}>Delete
                            </button>}

                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}