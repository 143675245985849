import React, {useState, useEffect} from "react";
import ListItem from "./ListItem";
import axios from "axios";
import APIService from "../../services/api.service";

export default function ActivitiesList(props) {

    const [activitiesData, updateActivitiesData] = useState();
    const [loading, setLoading] = useState(true);

    window.updateActivitiesData = updateActivitiesData;

    var api_url = "user_tasks/get_user_tasks";
    if(props.mode === 'assignee'){
        api_url = "user_tasks/get_user_assigned_tasks";
    }

    useEffect(() => {
            axios.get(APIService.API_URL +  api_url, {
                headers: {"Authorization": APIService.getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateActivitiesData(response.data.user_tasks);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, []);


    if (loading) {
        return <div/>;
    }

    return (
        <div className="row mt-lg-4 mt-2 mb-4">
            <div className="card">
                <div className="card-header p-3">
                    <div className="d-flex mt-n2">
                        <div
                            className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-rose'}>
                            <i className="material-icons opacity-10">checklist</i>
                        </div>
                        <div className="ms-3 my-auto">
                            <h4 className="mb-0">
                                {props.mode === 'owner' && "Personal activities"}
                                {props.mode === 'assignee' && "Invited activities"}
                            </h4>
                        </div>

                    </div>
                    <p className="mt-2">
                        {props.mode === 'owner' && "These are activities that you have created and included yourself."}
                        {props.mode === 'assignee' && "These are activities created by others that you have been invited into."}
                    </p>
                    <hr className="horizontal dark" />
                </div>
                <div className="card-body">
                    {activitiesData.length > 0 && <ul className="list-group list-group-flush">

                        {activitiesData.map((c, index) => (
                            <ListItem
                                data={c}
                                key={index}
                                index={index}
                                mode={props.mode}
                            />
                        ))}

                    </ul>}
                    {activitiesData.length < 1 &&
                        <p>You have no
                            {props.mode === 'owner' && " personal "}
                            {props.mode === 'assignee' && " invited "}
                            activities.</p>
                    }
                </div>
            </div>
        </div>
    );
};



