import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2';
import {isEmail, isMobilePhone} from "validator";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

const isValidContact = (value) => {
    if (!isEmail(value) && !isMobilePhone(value)) {
        return (
            <div className="text-primary" role="alert">
                Enter valid email address or mobile phone number.
            </div>
        );
    }
};


export default function AddAssigneeModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [assigneeID, setAssigneeID] = useState("");
    const [contact, setContact] = useState("");
    const [name, setName] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const contactMaxChars = 150
    const nameMaxChars = 150;

    const onChangeAssigneeID = (e) => {
        setAssigneeID(e.target.value);
    };

    const onChangeContact = (e) => {
        setContact(e.target.value);
        document.getElementById("assignee-contact-char-count").innerText = e.target.value.length;
    };

    const onChangeName = (e) => {
        setName(e.target.value);
        document.getElementById("assignee-name-char-count").innerText = e.target.value.length;
    };

    const onClickModalClose = () => {

        var modal = document.getElementById('add-assignee-modal');
        modal.style.display = "none";

        document.getElementById("assignee-contact-char-count").innerText = '0';
        document.getElementById("assignee-name-char-count").innerText = '0';

        setAssigneeID("");
        setContact("");
        setName("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickAssigneeDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Team member details will be deleted.',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn btn-primary m-1',
                cancelButton: 'btn btn-secondary m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteAssignee(assigneeID);
            }
        });
    }

    const setAssigneeModalFormFields = (data) => {

        document.getElementById("assignee-contact-char-count").innerText = data.data.contact.length;
        document.getElementById("assignee-name-char-count").innerText = data.data.name.length;

        setAssigneeID(data.data.id);
        setContact(data.data.contact)
        setName(data.data.name);

        setLoading(false);
        setSuccessful(false);
        setMessage("");


    }

    window.setAssigneeModalFormFields = setAssigneeModalFormFields;

    const addNewAssignee = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "user_assignees/add";
            var assignee = {
                "user_assignee_id": assigneeID,
                "assignee": {
                    "contact": contact, "name": name
                }
            }

            if (assigneeID !== "") {
                api_endpoint = "user_assignees/update";
            }

            axios.post(APIService.API_URL + api_endpoint, assignee,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    APIService.resetAssignees();
                    onClickModalClose();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Team member details have been saved.',
                        customClass: {
                            confirmButton: 'btn btn-primary m-1',
                            cancelButton: 'btn btn-secondary m-1'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }


    return (
        <div className="modal" tabIndex="-1" role="dialog" id="add-assignee-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewAssignee} className="navbar-form" id="form-add-assignee-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">Add a contact</h4>
                            <button className="close-btn btn btn-icon" type="button" onClick={onClickModalClose}>
                                <i className="material-icons">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="assignee-id" value={assigneeID} onChange={onChangeAssigneeID}/>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="contact" className="text-normal text-dark">Contact (email or mobile number)
                                        <Input type="text" id="form-add-assignee-contact" name="contact"
                                               className="form-control" value={contact}
                                               maxLength={contactMaxChars} placeholder="Email or phone number..."
                                               onChange={onChangeContact}
                                               validations={[required, isValidContact]}/>

                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="assignee-contact-char-count">0</span> / {contactMaxChars} chars.
                                </div>
                            </div>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="name" className="text-normal text-dark">Name
                                        <Input type="text" id="form-add-assignee-name" name="contact"
                                               className="form-control" value={name}
                                               maxLength={nameMaxChars} placeholder="Name..."
                                               onChange={onChangeName} />

                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="assignee-name-char-count">0</span> / {nameMaxChars} chars.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {assigneeID && <button type="button" className="btn btn-outline-danger" data-dismiss="modal"
                                               onClick={onClickAssigneeDelete}>Delete
                            </button>}
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}