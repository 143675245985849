import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};


const ResendConfirmation = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const username = e.target.value;
        setEmail(username);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            return axios
                .post(APIService.AUTH_URL + "password", {
                    'user': {
                        email
                    }
                }).then((response) => {

                    if (response.data.errors) {

                        var respStr = APIService.errorsArray(response.data.errors);
                        setMessage(respStr);
                        setLoading(false);
                        setSuccessful(false);
                    } else {
                        setLoading(false);
                        setSuccessful(true);
                        setMessage("");
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Your password reset link has been sent. Please check your email and follow the instructions.',
                            customClass: {
                                confirmButton: 'btn btn-primary m-1',
                                cancelButton: 'btn btn-secondary m-1'
                            },
                            buttonsStyling: false
                        });
                    }

                }).catch((error) => {
                    var resp = APIService.errorResponse(error);
                    setMessage(resp);
                    setLoading(false);
                    setSuccessful(false);
                });


        } else {
            setLoading(false);
        }
    };

    return (

        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                            <div
                                className="login-illustration position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                            ></div>
                        </div>
                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                            <div className="card card-plain">
                                <div className="card-header text-center">
                                    <h4 className="font-weight-bolder">Reset password</h4>
                                    <p className="mb-0">Enter your email address</p>
                                </div>
                                <div className="card-body mt-2">

                                    <Form onSubmit={handleSubmit} ref={form}>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={
                                                        successful ? "alert alert-success text-white" : "text-primary mb-3"
                                                    }
                                                    role="alert"
                                                >
                                                    <div dangerouslySetInnerHTML={{__html: message}}/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="input-group input-group-outline mb-3">
                                            <label className="text-normal text-dark">Email
                                                <Input type="email" className="form-control"
                                                       placeholder="shay@example.com"
                                                       name="email" value={email}
                                                       onChange={onChangeEmail}
                                                       validations={[required]}
                                                />
                                            </label>
                                        </div>


                                        <div className="input-group input-group-outline mb-3">
                                            <button className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                                                    disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span> Submit</span>
                                            </button>
                                        </div>

                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>

                                    </Form>
                                </div>
                                <div className="card-footer text-center pt-0 px-lg-2 px-1">
                                    <p>Not yet registered? <a href="/register"
                                                              className="text-primary text-gradient font-weight-bold">Sign
                                        up</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResendConfirmation;
