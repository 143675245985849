import React from "react";
import APIService from "../../services/api.service";

export default function Navbar(props) {

    function toggleSidenav() {
        // Toggle Sidenav
        let body = document.getElementsByTagName('body')[0];
        let className = 'g-sidenav-pinned';

        if (body.classList.contains(className)) {
            const closeLayer = document.getElementById('close-layer');
            setTimeout(function () {
                body.classList.remove(className);
                closeLayer.classList.remove('visible');
                closeLayer.parentNode.removeChild(closeLayer);
            }, 250);
        } else {
            body.classList.add(className);

            const mainPanel = document.getElementById('main-panel');
            const closeLayer = document.createElement('div');
            mainPanel.appendChild(closeLayer);
            closeLayer.setAttribute("id", "close-layer");
            closeLayer.classList.add('close-layer');
            closeLayer.addEventListener("click", function () {
                toggleSidenav();
            });

            const closeBtn = document.createElement('button');
            closeBtn.setAttribute("id", "iconSidenav");
            closeBtn.classList.add('rounded-circle');
            closeBtn.classList.add('cursor-pointer');
            closeBtn.classList.add('text-white');
            closeBtn.classList.add('position-absolute');
            closeBtn.classList.add('top-0');
            closeBtn.classList.add('end-0');
            closeBtn.innerHTML = '<i class="material-icons-round opacity-10">close</i>';
            closeLayer.appendChild(closeBtn);

            setTimeout(function () {
                body.classList.add(className);
                closeLayer.classList.add('visible');
            }, 250);

        }
    }

    function showAddListModal()  {
        document.getElementById('add-list-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a list';
        document.getElementById('add-list-modal').style.display = "block";
    }

    const showAddItemModal = (data) => {

        var currUser = APIService.getCurrentUser();
        var active_tasks = APIService.getActiveTasks();
        if (currUser && active_tasks) {
            var limitActivities = currUser.user.subscription_plan.activities;
            var active_num_tasks = active_tasks;

            if (active_num_tasks >= limitActivities) {
                APIService.showUpgradeAlert(active_num_tasks, limitActivities);
                return;
            }
        }

        document.getElementById('add-item-modal').style.display = "block";
    };

    window.onscroll = function () {
        blurNavbar()
    };

    function blurNavbar() {
        var navbar = document.getElementById("navbarBlur");

        if (window.pageYOffset >= 50) {
            navbar.classList.add("blur");
            navbar.classList.add("shadow-blur");
            navbar.classList.add("left-auto");
        } else {
            navbar.classList.remove("blur");
            navbar.classList.remove("shadow-blur");
            navbar.classList.remove("left-auto");
        }
    }

    function startTour() {
        window.startTour();
    }

    return (

        <nav
            className="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
            id="navbarBlur" data-scroll="true">
            <div className="container-fluid py-1 px-3">
                <nav aria-label="breadcrumb">
                    <h6 className="font-weight-bolder"></h6>
                </nav>
                <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        {/*<div className="input-group input-group-outline">
                            <label className="form-label">Search here</label>
                            <input type="text" className="form-control"/>
                        </div>*/}
                    </div>
                    <ul className="navbar-nav  justify-content-end">
                        <li className="nav-item dropdown pe-2 add-button-dropdown">
                            <a href="/" className="nav-link text-body p-0 position-relative" id="add-item-dropdown"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="material-icons me-sm-1 add-item-icon text-primary">
                                    add_circle_outline
                                </i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end p-2 me-sm-n4"
                                aria-labelledby="add-item-dropdown">
                                <li className="mb-2">
                                    <button className="dropdown-item border-radius-md" onClick={showAddItemModal}>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">task</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Add activity
                                                </h6>
                                            </div>
                                        </div>
                                    </button>
                                </li>
                                <li className="mb-2">
                                    <button className="dropdown-item border-radius-md"  onClick={showAddListModal}>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">checklist</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Add list
                                                </h6>
                                            </div>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown pe-2">
                            <a href="/" className="nav-link text-body p-0 position-relative" id="account-dropdown"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="material-icons me-sm-1">
                                    account_circle
                                </i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end p-2 me-sm-n4"
                                aria-labelledby="account-dropdown">
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="/account">
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">person</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Account
                                                </h6>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                {props.showTourLink === true && <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="#" onClick={startTour}>
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">privacy_tip</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Quick tour
                                                </h6>
                                            </div>
                                        </div>
                                    </a>
                                </li>}
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="/pricing">
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">credit_card</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Upgrade
                                                </h6>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="/logout">
                                        <div className="d-flex align-items-center py-1">
                                            <span className="material-icons">logout</span>
                                            <div className="ms-2">
                                                <h6 className="text-sm font-weight-normal my-auto">
                                                    Logout
                                                </h6>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                            <button onClick={toggleSidenav} className="nav-link text-body p-0" id="iconNavbarSidenav">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                    <i className="sidenav-toggler-line"></i>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}