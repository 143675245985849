import React, {useState, useEffect} from "react";
import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";


const ActivityInvitation = (props) => {

    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    var url = new URL(window.location);
    const [id, setID]  = useState(url.searchParams.get("id"));
    const [token, setToken]  = useState(url.searchParams.get("token"));
    const [response, setResponse]  = useState(url.searchParams.get("response"));

    useEffect(() => {

        if (token && id && response) {

            return axios
                .post(APIService.API_URL + "task_assignees/response",
                    {"task_assignee_id": id, "token": token, "response": response }
                ).then((response) => {

                    if (response.data.errors) {

                        var respStr = APIService.errorsArray(response.data.errors);
                        setMessage(respStr);
                        setLoading(false);
                        setSuccessful(false);
                    } else {
                        setLoading(false);
                        setSuccessful(true);
                        setMessage("You have " + response.data.task_assignee.status + " this activity invitation.");
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Your response has been submitted.',
                            customClass: {
                                confirmButton: 'btn btn-primary m-1',
                                cancelButton: 'btn btn-secondary m-1'
                            },
                            buttonsStyling: false
                        });
                    }

                }).catch((error) => {
                    var resp = APIService.errorResponse(error);
                    setMessage(resp);
                    setLoading(false);
                    setSuccessful(false);
                });


        } else {
            window.location.assign("/");
        }

    }, []);

    const onClickAccept = () => {
        window.location.assign("/activity_invitation?token="+ token + "&id=" + id + "&response=accept");
    }

    const onClickReject = () => {
        window.location.assign("/activity_invitation?token="+ token + "&id=" + id + "&response=reject");
    }

    return (

        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                            <div
                                className="login-illustration position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                            ></div>
                        </div>
                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                            <div className="card card-plain">
                                <div className="card-header text-center">
                                    <h4 className="font-weight-bolder">Activity invitation</h4>
                                </div>
                                <div className="card-body mt-2 mb-2">

                                    {loading && (
                                        <p className="c-grey-900 mB-20">
                                            <span className="spinner-border spinner-border-sm"></span> Submitting your response...</p>
                                    )}
                                    {message && (
                                        <div className="form-group">
                                            <div
                                                className={
                                                    successful ? "text-success mb-3  text-center" : "text-primary mb-3  text-center"
                                                }
                                                role="alert"
                                            >
                                                <div dangerouslySetInnerHTML={{__html: message}}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className=" d-none card-footer text-center pt-0 px-lg-2 px-1">
                                    <button className="btn btn-primary m-2" onClick={onClickAccept}>Accept</button>
                                    <button type="button" className="btn btn-secondary m-2" onClick={onClickReject}> Reject</button>
                                </div>
                                <div className="card-footer text-center pt-0 px-lg-2 px-1 mt-3">
                                    <p>Wondering what Reminded is all about? <a href="/" className="text-primary text-gradient font-weight-bold">Find out here</a>. </p>
                                    <p>Already signed up? <a href="/login" className="text-primary text-gradient font-weight-bold">Sign in</a></p>
                                    <p>Not registered yet? <a href="/register" className="text-primary text-gradient font-weight-bold">Sign up</a></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

);
};

export default ActivityInvitation;
