import React from "react";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";
import Profile from "./Profile";
import PaymentMethod from "./PaymentMethod";
import SubscriptionDetails from "./SubscriptionDetails";


const Account = () => {

    return (
        <div className="row mb-5">
            <div className="col-lg-3">
                <div className="card position-sticky top-1">
                    <ul className="nav flex-column bg-white border-radius-lg p-3">
                        <li className="nav-item">
                            <a className="nav-link text-dark d-flex" data-scroll="" href="#view-profile">
                                <i className="material-icons text-lg me-2">person</i>
                                <span className="text-sm">Profile</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2">
                            <a className="nav-link text-dark d-flex" data-scroll="" href="#update-profile">
                                <i className="material-icons text-lg me-2">manage_accounts</i>
                                <span className="text-sm">Update profile</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2">
                            <a className="nav-link text-dark d-flex" data-scroll="" href="#update-password">
                                <i className="material-icons text-lg me-2">lock</i>
                                <span className="text-sm">Change password</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2">
                            <a className="nav-link text-dark d-flex" data-scroll="" href="#subscription-details">
                                <i className="material-icons text-lg me-2">thumb_up_off_alt</i>
                                <span className="text-sm">Subscription details</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2">
                            <a className="nav-link text-dark d-flex" data-scroll="" href="#payment-method">
                                <i className="material-icons text-lg me-2">credit_card</i>
                                <span className="text-sm">Billing information</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-9 mt-lg-0 mt-4">
                <Profile />
                <UpdateProfile />
                <UpdatePassword />
                <SubscriptionDetails />
                <PaymentMethod />
            </div>
        </div>
    );
};

export default Account;
