import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import axios from "axios";

export default function CategoriesGrid() {

    const [loading, setLoading] = useState(true);
    const [categoriesData, updateCategoriesData] = useState();
    window.updateCategoriesData = updateCategoriesData;

    useEffect(() => {

        axios.get(APIService.API_URL + "user_tasklists/get",
            {headers: {"Authorization": APIService.getHeaders().authorization}})
            .then((response) => {

                if (response.data.errors) {
                    // do nothing
                } else {
                    sessionStorage.setItem("user_tasklists", JSON.stringify(response.data.user_tasklists));
                    sessionStorage.setItem("active_tasks", response.data.active_tasks);
                    updateCategoriesData(APIService.getTasklists());
                    setLoading(false);
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    const onClickList = (e) => {
        window.location.assign("/list/"+e);
    };

    const showEditListModal = (c) => {
        window.setListModalFormFields({data: c});
        document.getElementById('add-list-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit your list';
        document.getElementById('add-list-modal').style.display = "block";
    };

    function showAddListModal()  {
        document.getElementById('add-list-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a list';
        document.getElementById('add-list-modal').style.display = "block";
    }

    return (
        <div className="row row mt-lg-4 mt-2">
            {categoriesData && categoriesData.length > 0 && categoriesData.map((c, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-5">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex mt-n2">
                                <div className={'avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-' + c.color}>
                                    <i className="material-icons opacity-10">checklist</i>
                                </div>
                                <div className="ms-3 my-auto">
                                    <h6 className="mb-0"><a href="#"  onClick={() => onClickList(c.id)}>{c.title}</a></h6>
                                </div>
                                <div className="ms-auto">
                                    <div className="dropdown">
                                        <button className="btn btn-link text-secondary ps-0 pe-2" id="navbarDropdownMenuLink"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v text-lg"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
                                             aria-labelledby="navbarDropdownMenuLink">
                                            <button className="dropdown-item border-radius-md" onClick={() => onClickList(c.id)}>Open</button>
                                            <button className="dropdown-item border-radius-md" onClick={() => showEditListModal(c)}>Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="horizontal dark" />
                            <div className="row">
                                <div className="col-6">
                                    <h6 className="text-sm mb-0">{c.num_tasks}</h6>
                                    <p className="text-secondary text-sm font-weight-normal mb-0">{c.num_tasks === 1 ? "Activity" : "Activities"}</p>
                                </div>
                                <div className="col-6 text-end">
                                    <h6 className="text-sm mb-0">{c.num_assignees}</h6>
                                    <p className="text-secondary text-sm font-weight-normal mb-0">{c.num_assignees === 1 ? "Assignee" : "Assignees"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {(!categoriesData || categoriesData.length < 1) &&
            <div className="ms-auto">
                <button className="btn bg-gradient-primary m-3" onClick={showAddListModal}>+&nbsp; Add your first list</button>
            </div>}
        </div>
    )
}