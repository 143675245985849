import React, {useState} from "react";
import APIService from "../../services/api.service";

export default function PaymentMethod() {

    const [payMethodCurrentUser, setPayMethodCurrentUser] = useState(APIService.getCurrentUser());
    window.setPayMethodCurrentUser = setPayMethodCurrentUser;

    if(!payMethodCurrentUser.user.card_4_digits || payMethodCurrentUser.user.card_4_digits === ""){
        return <div/>;
    }

    const onClickChange = () => {
        window.setPayFormFields({mode: 'change', new_plan_id: ''});
        document.getElementById('payment-modal').style.display = "block";
    }

    return (

        <div className="card mt-4"  id="payment-method">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h5 className="mb-0">Billing information</h5>
                    </div>
                    <div className="col-6 text-end">
                        <button className="btn bg-gradient-dark mb-0" onClick={onClickChange}><i
                            className="material-icons text-sm">credit_card</i>&nbsp;&nbsp;Change</button>
                    </div>
                </div>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-6 mb-md-0 mb-4">
                        <div
                            className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                            <h6 className="mb-0 text-capitalize"><span>{payMethodCurrentUser.user.card_brand}</span>&nbsp;&nbsp;&nbsp;****&nbsp;
                                &nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{payMethodCurrentUser.user.card_4_digits} &nbsp;&nbsp;Exp: <span>{payMethodCurrentUser.user.card_expiry}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
